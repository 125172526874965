.sweet-alert {
	font-family: $font-family;
	padding: 2em;

	@include breakpoint(small only) {
		padding: 1em;

		p {
			font-size: 1em;
		}

		h2 {
			font-size: 1.2em;
			line-height: 1em;
		}
	}

	button {
		background: $clr-primary!important;

		&:hover {
			background: darken($clr-primary, 20%);
		}
	}
}