.row {
	width: $global-width;
	max-width: 100%;

	.row {
		max-width: 100%;
	}
}

.full-width {
	max-width: 100%;
	width: 100%;
}

body {
	display: flex;
	flex-direction: column;
	background: $clr-white;
}

* {
	@include breakpoint(medium up) {
		//flex-shrink: 0;
	}
}

.section {
	padding: 70px 30px;
	width: 100%;

	&.white {
		background: $clr-white;
	}
}

.bg-right {
	background-position: right;
}

.bg-left {
	background-position: left;
}

.content-padded {
	&.top #content {
		padding-top: 30px;
	}

	&.bottom #content {
		padding-bottom: 50px;
	}
}