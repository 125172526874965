#box-sizes {
	.columns {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
	}
}

&.boxes {
	padding-bottom: 40px;

	h2 {
		font-size: 2em;
	}

	.uses {
		margin-top: -30px;
		color: $clr-text;
		text-align: center;
		font-weight: bold;
	}
}