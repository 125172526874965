.reveal {
	padding: 20px;
	border-radius: 10px;
	border: none;
	max-width: 100%;

	h2, .modal-title {
		border-bottom: 2px solid #E8EBED;
		text-align: center;
		font-size: 2em;
		color: $clr-text;
		margin: -20px;
		margin-bottom: 20px;
		padding: 20px;

		@include breakpoint(small only) {
			font-size: 1.8em;
		}
	}

	&:focus {
		outline: none;
	}

	@include breakpoint(small only) {
		border-radius: 0px;
		max-height: 100vh;
		max-width: 100vw;

		overflow-y: auto;
	}
}

.reveal-overlay {
	overflow: hidden;
	background: fade-out(#000, .4);
}

.close-button {
	display: block;
	font-size: 1.1em;
	color: $clr-text;
	border-radius: 50%;
	border: 3px solid $clr-text;

	padding: 3px;
	padding-top: 4px;
	width: 33px;
	height: 33px;
	text-align: center;

	transition: none;

	&:hover {
		color: $clr-white;
		background: $clr-text;
	}
}

@include breakpoint(medium down) {
	html.is-reveal-open body {
		height: auto;
	}
}
