#order-content {
	width: 1300px;

	@include breakpoint(small only) {
		> .columns {
			padding: 0px;
		}
	}

	@include breakpoint(medium down) {
		> div:first-child {
			order: 2;
		}
	}

	overflow: visible;
}



.ng-cloak {
	display: none!important;
}

.pac-container {
	* {
		font-family: 'Montserrat', sans-serif;
	}

	.pac-item-query {
		font-size: 1rem;
		margin-right: 10px;
		font-weight: normal;
		color: $clr-text;
	}

	.pac-item {
		font-size: 0.8rem;
	}
}

.form-angular-error {
	margin-top: -0.5rem;
	margin-bottom: 1rem;
	font-size: 0.75rem;
	font-weight: bold;
	color: #ec5840;
}

#order {
	background: $clr-off-white;

	@include breakpoint(large up) {
		height: 100vh;
	}

	#order-loading, #order-submitting {
		position: fixed;
		top: 0px;
		bottom: 0px;
		left: 0px;
		right: 0px;

		background: $clr-white;
		color: $clr-text;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 999999;

		> div {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 30px;
			font-weight: bold;
			text-align: center;

			i {
				margin-bottom: 10px;
			}
		}
	}

	#got-a-question {
		@include breakpoint(medium down) {
			width: 100%;
			border-top: 1px solid $clr-off-white;
			padding-top: 10px;
			text-align: center;

			br {
				display: none;
			}
		}
	}

	#order-submitting {
		background: fade-out($clr-white, .2);
	}

	#content {
		padding-bottom: 30px;
		overflow-y: auto;
		flex-shrink: 1;
		flex-grow: 1;
		margin-top: 0px;
		padding-top: 40px;
		position: relative;

		@include breakpoint(medium down) {
			padding-top: 0px;
			padding-bottom: 0px;
		}
	}

	#order-header {
		background: $clr-white;
		display: flex;
		align-items: center;
		padding: 20px 40px;
		padding-top: 30px;
		border-bottom: 2px solid #E8EBED;
		//position: fixed;
		margin-bottom: 0px;
		top: 0px;
		right: 0px;
		left: 0px;
		z-index: 99;
		flex-shrink: 0;

		.logo {
			@include breakpoint(medium only) {
				text-align: center;
			}
		}

		@include breakpoint(small only) {
			padding: 10px 0px;

			.columns {
				text-align: center;
				padding-left: 20px;
				padding-right: 20px;
			}

			.logo {
				width: 140px;
			}

			> .row {
				> .columns {
					&:first-child {
						margin-bottom: 30px;
					}
				}
			}
		}
	}

	.back-box {
		a {
			color: $clr-text;
		}
		
		line-height: 28px;
		margin-bottom: 10px;
	}

	.order-timeline {
		@include breakpoint(medium only) {
			margin-top: 30px;
		}

		&, ul {
			display: flex;
			align-items: center;
		}

		justify-content: center;

		ul {
			list-style: none;
			padding-left: 0px;
			margin-left: 0px;

			li {
				display: flex;
				flex-direction: column;
				align-items: center;
				color: #E8EBED;
				position: relative;
				cursor: pointer;
				width: 80px;
				flex-shrink: 0;

				@include breakpoint(medium up) {
					margin-right: 30px;

					&:after {
						position: absolute;
						top: calc(19px);
						left: 60px;
						height: 2px;
						width: 70px;
						background: #E8E8ED;
						content: ' ';
					}

					&:last-child {
						margin-right: 0px;

						&:after {
							display: none;
						}
					}
				}

				@include breakpoint(medium up) {
					.stage-num {
						border: 2px solid #E8EBED;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 40px;
						height: 40px;
						text-align: center;
					}

					.stage-label {
						width: 80px;
						margin-top: 5px;
						text-align: center;
						font-size: 0.78em;
					}
				}

				@include breakpoint(small only) {
					color: $clr-highlight;
					font-weight: bold;
					display: none;

					.stage-num {
						display: inline;

						&:before {
							content: 'Step ';
						}

						&:after {
							content: ':';
						}
					}
				}

				&.current {
					display: flex;

					@include breakpoint(medium up) {
						.stage-num {
							border: 2px solid $clr-primary;
							color: $clr-text;
						}

						.stage-label {
							color: $clr-text;
						}
					}

					@include breakpoint(small only) {
						display: block;
					}
				}

				&.complete {
					@include breakpoint(medium up) {
						.stage-num {
							border: 2px solid $clr-primary;
							background: $clr-primary;
							color: $clr-white;
						}

						.stage-label {
							color: $clr-text;
						}

						&:after {
							background: $clr-primary;
						}
					}
				}
			}
		}
	}


	form {
		width: 100%;
	}

	&.where-page {
		#content {
			flex-grow: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			background-image: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url('/assets/img/where-image.jpg');
			background-position: bottom center, bottom center;
			background-repeat: no-repeat, no-repeat;
			background-size: cover, cover;

			.form-error {
				display: block;
			}

			button {
				min-width: 145px;
				text-align: center;
			}

			@include breakpoint(small only) {

				form > .row {
					margin-left: 0px;
					margin-right: 0px;

					> .columns {
						padding-left: 0px;
						padding-right: 0px;
					}
				}

				.order-box {

					.button {
						width: calc(100% + 40px);
						margin: -20px;
						border-radius: 0px;
						margin-top: 20px;
					}
				}
			}

			@include breakpoint(medium only) {
				.order-box {
					max-width: 80vw;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
}

.order-box {
	background: $clr-white;
	padding: 40px;
	border: 2px solid #E8EBED;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;

	@include breakpoint(small only) {
		margin: 0px;
		padding: 20px;
		border-radius: 0px;
		border: none;
	}

	@include breakpoint(medium down) 
	margin-top: 20px;

	h2 {
		width: calc(100% + 80px);
		margin-top: -40px;
		margin-left: -40px;
		margin-right: -40px;
		margin-bottom: 40px;
		padding: 25px 52px;
		font-size: 1.7em;
		display: flex;
		align-items: center;
		color: $clr-text;
		border-bottom: 2px solid #E8EBED;

		@include breakpoint(small only) {
			margin: -20px;
			margin-bottom: 20px;
			padding: 10px 20px;
			font-size: 1.3em;
			width: calc(100% + 40px);
				//flex-direction: column;
				//align-items: flex-start;

				> span:not(.pickup-date) {
					width: 36px;
					height: 36px;
					border-radius: 50%;
					box-sizing: border-box;
					border: 2px solid $clr-primary;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					margin-bottom: 0px;
				}
			}

			@include breakpoint(small only) {
				margin-top: 9px;
			}

			> span:not(.pickup-date) {
				//border-bottom: 2px solid $clr-primary;
				&:after {
					content: ':';
				}

				margin-right: 10px;

				@include breakpoint(small only) {
					margin-right: 10px;
					//margin-bottom: 5px;
					//margin-right: 0px;
					//display: block;
					&:after {
						content: '';
					}
				}
			}

			img {
				margin-right: 15px;
				@include breakpoint(small only) {
					display: none;
				}
			}

			flex-wrap: wrap;

			small {
				width: 100%;
				margin-top: 20px;
				font-size: 0.58em;
				color: $clr-text;
				line-height: 1.3em;
				font-weight: 300;
			}

			.pickup-date {
				font-size: 0.6em;
				margin-left: auto;
				margin-right: 0px;
				color: $clr-text;

				span {
					color: $clr-highlight;
					font-weight: bold;
				}

				@include breakpoint(small only) {
					display: block;
					width: 100%;
					margin-right: 0px;
					margin-left: 0px;
					margin-top: 10px;
					font-size: 0.8em;
				}
			}
		}

		.next-prev-holder {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;
			align-self: flex-end;

			margin-right: 25px;

			.button {
				span > span {
					margin-right: 10px;
				}
			}

			.previous {
				text-align: center;
				color: $clr-dulled;
				text-decoration: underline;
				font-size: 0.8em;
				align-self: center;
				padding: 10px;
			}

			@include breakpoint(small only) {
				margin: 0px;
				margin-top: 20px;
				padding: 0px;
				width: 100%;
				align-items: stretch;

				.button {
					display: block;
					border-radius: 15px;
					margin: 0px;
				}
			}

			@include breakpoint(medium only) {
				.button {
					i {

					}
				}
			}
		}

		.button {
			padding: 1.3em 1.6em;
			margin-top: 20px;

			@include breakpoint(small only) {
				padding: 1em 1em;
			}

			&.next {
				width: auto;
				display: flex;
				align-items: center;
				font-size: 1.1em;

				@include breakpoint(small only) {
					font-size: 1.3em;
				}

				&.squared {
					border-radius: 10px;
				}

				&:after {
					@include fa-icon;
					content: $fa-var-angle-right + $fa-var-angle-right + $fa-var-angle-right;
					font-weight: bold;
					margin-left: 40px;
					font-size: 1.5em;

					@include breakpoint(medium down) {
						//margin-left: auto;
					}
				}
			}
		}

		label {
			color: $clr-text;
			input,textarea,select,.wrap-dd-select {
				margin-top: 10px;
			}
		}
	}


	#order {

		#pricing-tabs {
			margin: 0 auto;
			margin-bottom: 10px;
			width: auto;
			align-self: center;

			@include breakpoint(small only) {
				width: 100%;
				margin: 0px;
			}

			.tabs-title {
				min-width: 170px;
				text-align: center;

				@include breakpoint(small only) {
					min-width: 50%;
				}
			}

		}

		#rental-period-slider {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0px 30px;
			width: 100%;
			margin-bottom: 30px;

			@include breakpoint(small only) {
				padding: 0px 10px;
				margin-bottom: 10px;
			}

			label {
				color: #000;
				font-size: 1.2em;
				text-align: center;
				line-height: 1.2em;
			}

			> div {
				width: auto;
				flex-grow: 1;
				margin-left: 40px;

				@include breakpoint(small only) {
					width: 100%;
					margin-left: 0px;
				}
			}

			select {
				width: 100%;
				margin-top: 20px;
			}

			@include breakpoint(small only) {
				flex-direction: column;
				margin-top: 20px;

				label {
					width: 100%;
					br {
						display: none;
					}
				}

				.rzslider {
					display: none;
				}
			}
		}

		#box-pricing-table {
			.columns {
				margin-bottom: 2em;
				display: flex;
				flex-direction: row;
				align-items: stretch;
			}

			.tabs-content, .tabs-panel {
				background: none;
				padding: 0px;
				margin: 0px;
				border: none;
			}

			.tabs-panel {
				> .row {
					margin-left: 0px;
					margin-right: 0px;
				}
			}
		}

		.package {
			text-align: center;
			background: $clr-secondary;
			color: $clr-white;
			line-height: 1.8em;
			border-radius: 6px;
			overflow: hidden;
		//height: 100%;
		flex-shrink: 1;
		width: 100%;

		display: flex;
		flex-direction: column;
		align-items: center;
		align-self: stretch;

		h3 {
			text-align: center;
			color: $clr-white;
			font-size: 1.5em;
			margin-bottom: 0px;
			margin-top: 20px;
			border-bottom: 0px;
		}

		.package-count {
			color: $clr-highlight;
			font-weight: bold;
			margin-bottom: 20px;
			font-size: 1.2em;
		}

		.package-short-description, .package-description {
			font-size: 0.8em;
			padding: 5px 10px;
			line-height: 1.3em;
			margin-bottom: 20px;

			@include breakpoint(small only) {
				font-size: 1em;
			}
		}

		.package-description {
			padding: 10px;
			font-weight: bold;
			margin-bottom: 20px;
		}

		.package-note {
			margin-bottom: 5px;
			font-size: 0.8em;
		}

		.package-order-now {
			background: #1C1B26;
			//margin-top: auto;
			padding: 20px;
			width: 100%;
		}

		.package-price {
			font-size: 1.25em;
			color: $clr-highlight;
			margin-bottom: 20px;
			margin-top: auto;

			@include breakpoint(small only) {
				font-size: 1.5em;
			}

			i {
				margin-left: 5px;
				color: $clr-white;
				font-size: 0.8em;
			}

			&.no-include {
				margin-top: auto;
			}

			.dollar {
				font-size: 0.8em;
			}
		}

		.whats-included {
			border-bottom: 1px solid fade-out($clr-white, 0.75);
			color: $clr-white;
			margin-top: auto;
			margin-bottom: 10px;
			font-size: 0.8em;

			i {
				margin-right: 3px;
			}
		}

		.button {
			margin-top: 0px;
			display: block;
			color: $clr-white;
			margin-bottom: 0px;
			font-size: 1em;
			padding: 1.2em 1.8em;

			&.selected {
				color: $clr-white;
				background: $clr-primary;
			}
		}
	}

	.security-note {
		display: flex;
		align-items: center;
		font-size: 1em;

		img {
			margin-right: 10px;
			width: auto;
			flex-shrink: 0;
		}

		span {
			flex-shrink: 1;
			color: $clr-highlight;
			font-weight: bold;
		}
	}

	#packing-supplies, #moving-supplies {
		.next-prev-holder {
			margin-top: 0px;
		}
	}

	@include breakpoint(small only) {
		form  {
			#drop-off, #pick-up, #payment-details {
				.row {
					margin-left: 0px;
					margin-right: 0px;

					> .columns {
						padding-left: 0px;
						padding-right: 0px;
					}
				}
			}
		}
	}

	#payment-details {
		.next-prev-holder {
			@include breakpoint(medium up) {
				margin-left: auto;
			}

			.previous {
				display: none;
			}
		}

		#payment-submission-row {
			margin-top: 30px;

			@include breakpoint(small only) {
				margin-left: 0px;
				margin-right: 0px;
				margin-bottom: 40px;
			}
		}
	}

	#sticky-anchor {
		max-height: 100%!important;

		.order-box {
			width: 250px;
			max-width: auto;
		}

		@include breakpoint(large up) {
			position: fixed;
			width: 25%;
			top: 10.9em;

			.order-box {
				width: calc(100% - 15px);
			}
		}

		@include breakpoint(xlarge up) {
			width: 325px;

			.order-box {
				width: 325px;
			}
		}

		@include breakpoint(medium down) {
			&.sticky-container {
				#order-cart {
					display: none;
				}
			}
			//position: static;
			order: 1;
			width: 100%;

			.order-box {
				width: 100%;
			}
		}
	}

	.sticky {
		width: 100%;
	}

	#order-cart {
		padding: 0px;
		max-height: calc(100vh - 31px);
		//overflow-y: auto;

		@include breakpoint(medium down) {
			max-height: auto;
			height: auto;
			overflow-y: hidden;
			min-width: 100%;
		}

		@include breakpoint(medium down) {
			margin: 0px;
			//padding: 20px;
			border-radius: 0px;
			border: none;
		}

		@include breakpoint(large up) {
			max-height: calc(100vh - 217px);
		}

		.cart-scroller {
			overflow-y: auto;
			flex-shrink: 1;
			padding: 20px;

			@include breakpoint(medium down) {
				max-height: calc(100% - 45px);
			}
		}

		h2 {
			width: 100%;
			padding: 20px;
			padding-bottom: 0px;
			text-align: left;
			border-bottom: 0px;
			margin: 0px;
			margin-bottom: 20px;
			color: #000;
			font-size: 1.3em;
		}

		h4 {
			text-transform: uppercase;
			font-size: 0.68em;
			display: flex;
			align-items: center;

			a {
				margin-left: auto;
				color: $clr-primary;
			}
		}

		.invalid-package {
			border-bottom: none;
			color: map-get($foundation-palette, 'warning');
			margin-right: 5px;
		}

		.basket-supplies {
			margin-top: 10px;
			margin-bottom: 10px;
		}

		.basket-item {
			display: flex;
			align-items: center;
			margin-bottom: 3px;
			font-weight: 200;
			font-size: 1em;

			.basket-item-price {
				margin-left: auto;
				color: $clr-text;
				font-size: 1em;
				font-weight: normal;
			}
		}

		.basket-item-quantity {
			color: $clr-secondary;
			font-weight: bold;
			margin-right: 10px;
			text-align: center;
			display: flex;
			align-items: center;
			width: 55px;

			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;

			a:first-child {
				margin-right: auto;
			}

			a:last-child {
				margin-left: auto;
			}

			a {
				color: $clr-primary;
			}

			i {
				font-size: 0.7em;
			}

			.fa-trash {
				position: relative;
				top: -1px;
				color: #D75252;
			}
		}

		.basket-package {
			border-bottom: 2px solid #E8EBED;
			padding-bottom: 20px;
			margin-bottom: 10px;

			.customise-package {
				color: $clr-highlight;
				text-transform: uppercase;
				font-size: 0.7em;
				margin-top: 10px;
				font-weight: bold;
				display: block;
			}
		}

		.basket-times {
			background: #EDFFED;
			margin-left: -20px;
			margin-right: -20px;
			padding: 15px 20px;
			font-weight: 200;

			.time {
				display: block;
				font-size: 0.78em;
			}
		}

		.basket-total {
			background: $clr-primary;
			padding: 20px;
			color: $clr-white;
			display: flex;
			align-items: center;

			@include breakpoint(large up) {
				border-radius: 0px 0px 10px 10px;
			}

			span {
				margin-left: auto;
				font-size: 1.23em;
			}
		}
	}


	#cart-container {
		@include breakpoint(medium down) {
			order: 1;
			position: relative;
		}

		&.open {
			#sticky-anchor {
				#order-cart {
					display: flex;
					flex-direction: column;

					&.is-stuck, &.is-at-bottom {
						margin-top: 31px!important;
					}
				}
			}
		}
	}

	@include breakpoint(medium down) {
		#cart-toggle-container {
			width: 100%;
		}

		#cart-toggle {
			background: $clr-primary;
			display: flex;
			padding: 5px 15px;
			color: $clr-white;
			min-width: 100%;
			right: 0px;
			left: 0px;
			z-index: 90;

			> span:not(.price) {
				margin-left: 5px;
			}

			span.price {
				margin-left: auto;
			}

			i {
				margin-top: 4px;
				margin-left: 10px;
			}
		}

		#cart-toggle, #order-cart {
			&.is-at-bottom {
				position: fixed;
				top: 0px!important;
			}

			&.is-stuck-at-top {
				position: static;
			}
		}
	}


	.products-table {

		.row {
			margin-left: 0px;
		}

		@include breakpoint(small only) {
			margin-left: -20px;
			margin-right: -20px;
			width: calc(100% + 40px);
			margin-bottom: 20px;

			.row {
				margin: 0px;
			}
		}

		.columns {
			margin-bottom: 20px;
			display: flex;
			flex-direction: row;
			align-items: stretch;

			@include breakpoint(small only) {
				padding: 0px;
				margin-bottom: 0px;
			}
		}

		.product {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			background: $clr-white;
			border: 2px solid #E8EBED;
			border-radius: 6px;
			//min-height: 100%;
			//height: 100%;
			align-self: stretch;
			padding: 20px;
			margin-bottom: 0px;
			flex-shrink: 1;
			width: 100%;

			@include breakpoint(small only) {
				border: 0px;
				padding-bottom: 0px;
				height: 100vh;
				min-height: 100%;
			}

			h3 {
				text-align: center;
				margin-bottom: 10px;
				color: $clr-text;
				font-size: 1.7em;
				font-weight: normal;
				height: 2.7em;
			}

			img {
				align-self: center;
				max-height: 180px;

				@include breakpoint(small only) {
					flex-shrink: 1;
					max-height: 128px;
				}
			}

			.product-tagline {
				color: #6BC3FE;
				font-weight: bold;
				text-align: center;
				margin-bottom: 20px;
				margin-top: 20px;

				@include breakpoint(small only) {
					font-size: 1.2em;
				}
			}

			.checklist {
				li:before {
					color: $clr-primary;
				}

				font-size: 0.7em;

				li {
					margin-bottom: 10px;
				}
			}

			.product-description {
				margin-bottom: 0px;
				height: 8.5em;
				overflow-y: auto;

				.checklist {
					margin-bottom: 10px;
				}

				@include breakpoint(small only) {
					font-size: 1.3em;
					height: auto;
					text-align: left;
					overflow-y: hidden;
					flex-shrink: 0;
				}
			}

			.product-order {
				background: $clr-secondary;
				margin: -20px;
				margin-top: 10px;
				padding: 20px;

				@include breakpoint(small only) {
					margin-bottom: 0px;
				}
			}

			.button {
				padding: 1em 2em;
				text-align: center;
				width: 100%;
				display: block;
				margin: 0px;
				color: $clr-white;
				font-size: 1em;

				i {
					margin-right: 10px;
				}

				@include breakpoint(small only) {
					font-size: 1.6em;
				}
			}

			.product-price {
				text-align: center;
				color: $clr-text;
				margin-bottom: auto;
				font-size: 0.9em;
				margin-top: auto;

				@include breakpoint(small only) {
					font-size: 1.2em;
				}
			}
		}
	}

	.has-note {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		input {
			width: 100%;
		}
	}

	.pickup-windows {
		border-bottom: 2px solid #E8EBED;

		padding-bottom: 20px;
		margin-bottom: 20px;
	}


	#pick-up, #personal-details {
		padding-left: 0px;
		padding-right: 0px;

		h2 {
			margin-left: 0px;
			margin-right: 0px;
			width: 100%;
		}

		.row {
			padding-left: 40px;
			padding-right: 40px;
			margin-left: 0px;
			margin-right: 0px;

			@include breakpoint(small only) {
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		@include breakpoint(small only) {
			.next-prev-holder {
				margin-left: 0px;
				margin-right: 0px;
				width: 100%;
			}
		}

		.button {
			margin-right: 40px;
			margin-left: 40px;

			@include breakpoint(small only) {
				margin-left: 0px;
				margin-right: 0px;
			}
		}

		.previous {
			margin-right: 40px;

			@include breakpoint(small only) {
				margin-right: 0px;
			}
		}
	}

	label.has-checkbox {
		input[type="checkbox"] {
			margin-left: 10px;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		display: flex;
		align-items: center;
	}

	.input-note {
		margin-left: auto;
		font-size: 0.6em;
		font-weight: bold;

		@include breakpoint(small only) {
			width: 100%;
			text-align: left;
			font-size: 0.8em;
		}

		.input-warning {
			color: map-get($foundation-palette, 'warning');
		}

		.input-success {
			color: map-get($foundation-palette, 'success');
		}

		.input-error {
			color: map-get($foundation-palette, 'alert');
		}
	}

	input,select,textarea {
		&.ng-touched:not(.ng-pristine):not(:focus) {
			&.ng-invalid:not(:focus), &.ng-invalid-cc-number-type {
				background-color: rgba(236, 88, 64, 0.1);
				border-color: #ec5840;
			}
		}
	}

	.form-error[ng-hide] {
		display: block;
	}

	.card-wrapper {
		position: relative;

		.card-images {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 10px;

			img {
				transition: filter .5s ease-in-out, opacity .5s ease-in-out;

				&:not(.active) {
					-webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
					filter: grayscale(100%);
					opacity: .4;

					@include breakpoint(medium only) {
						display: none;
					}
				}
			}
		}
	}
}

.error-list {
	margin: 0px;
	padding: 0px;
	list-style: none;

	&:first-child {
		margin-top: 10px;
	}

	@include breakpoint(small only) {
		font-size: 0.8em;
	}

	color: map-get($foundation-palette, 'alert');
}

#customisationModal {
	max-width: 100%;
	width: 500px;

	.change-mind {
		font-size: 0.8em;
		text-align: center;
		color: $clr-dulled;

		.or {
			display: block;
			text-align: center;
		}

		a {
			color: $clr-dulled;
			text-decoration: underline;
		}
	}

	.package-item-row {
		display: flex;
		align-items: center;
		padding: 10px 15px;
		color: $clr-text;

		@include breakpoint(small only) {
			justify-content: center;
			flex-wrap: wrap;
		}

		.package-item-info {
			flex-grow: 1;

			span {
				flex-shrink: 1;
			}

			@include breakpoint(small only) {
				width: 100%;
				text-align: center;
				margin-bottom: 10px;
			}

			.package-item-title {
				font-size: 1.6em;
				color: #010101;

				@include breakpoint(small only) {
					font-size: 1.3em;
					text-aling: center;
				}
			}

			.package-item-dimensions {
				color: $clr-text;
			}
		}

		.package-item-quantity {
			display: flex;
			flex-direction: row;
			align-items: center;

			i {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				width: 50px;
				height: 50px;

				border: 2px solid $clr-primary;
				color: $clr-primary;

				font-size: 1.4em;

				padding-top: 4px;
				text-align: center;
				padding-left: 1px;
			}

			a {
				&[disabled] {
					cursor: not-allowed;

					i {
						color: fade-out($clr-primary, .5);
						border-color: fade-out($clr-primary, .5);
					}
				}
			}

			.quantity {
				font-size: 1.6em;
				color: $clr-primary;
				font-weight: bold;
				margin: 10px;
				display: block;
				width: 2em;
				text-align: center;

				input {
					&, &:focus, &:hover {
						border: none;
						font-weight: bold;
						box-shadow: none;
						text-align: center;
						background: none;
						color: $clr-primary;
						padding: 0px;
						display: flex;
						align-items: center;
						margin: 0px;
					}
				}
			}
		}
	}

	.package-total-row {
		margin: 10px -20px;
		padding: 30px;
		background: #EDFFED;

		display: flex;
		flex-wrap: wrap;

		h4 {
			width: 100%;
			text-transform: uppercase;
			color: $clr-text;
			font-size: 1em;
		}

		.package-name, .package-price {
			font-size: 1.3em;
		}

		.package-price {
			margin-left: auto;
		}

		.package-fee {
			font-size: 0.9em;
			width: 100%;
		}

		.package-line-item, .package-line-item-value {
			width: 50%;
		}

		.package-line-item-value {
			text-align: left;
			min-width: 55px;
			width: auto;
			margin-left: auto;
		}
	}

	p {
		color: #000;
		text-align: center;
		margin-top: 15px;
		font-weight: 200;
		font-size: 0.75em;
	}

	.button {
		margin-left: auto;
		margin-right: auto;
		display: block;
		width: 50%;

		@include breakpoint(small only) {
			width: 100%;
		}
	}

	.close-button {
		font-size: 1.5em;
	}
}

.include-modal {
	width: 800px;
	max-width: 90%;
	max-height: 80%;
	height: auto;
	min-height: auto;
	padding: 0px;
	position: absolute;
	top: 50%!important;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: hidden;
	height: auto;
	align-items: stretch;
	display: none;

	@include breakpoint(small only) {
		width: 100%;
		max-width: 100%;
		height: 100%;
		max-height: 100%;
		top: 0px!important;
		transform: none;
		left: 0px;
		bottom: 0px;
		right: 0px;

		.close-button {
			padding-top: 5px;
		}
	}

	.checklist {
		li {
			margin-bottom: 15px;

			&:before {
				color: $clr-primary;
			}
		}
	}

	.modal-inner {
		max-height: 100%;
		width: 100%;
		display: flex;
		flex-shrink: 1;
		flex-direction: column;
		align-items: center;

		.modal-title {
			text-align: center;
			padding: 20px 40px;
			padding-top: 40px;
			align-self: stretch;
		}

		.return-link {
			margin-top: 10px;
			color: $clr-dulled;
			text-decoration: underline;
		}

		.included-image {
			flex-shrink: 2;
			max-height: 100%;
			max-width: 100%;
			height: auto;
			width: auto;
			border-radius: 0px;
		}
	}
}

.reveal-overlay .include-modal {
	display: flex;
}