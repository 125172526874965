.button {
	border-radius: 100px;
	background: none;
	border: none;

	padding: 1.1em 2em;
	font-weight: bold;
	font-size: 1em;

	&.default {
		border: 3px solid $clr-primary;
		color: $clr-secondary;

		&:hover, &:focus {
			background: $clr-primary;
			color: $clr-white;
		}
		
	}

	&.success {
		background: $clr-primary;
		box-shadow: inset 0px -3px 0px 0px rgba(0,0,0,0.10);

		&[disabled] {
			&:hover, &:focus {
				background: darken($clr-primary, 20%);	
			}
		}
	}

	&.dark {
		background: $clr-secondary;
		box-shadow: inset 0px -3px 0px 0px rgba(0,0,0,0.10);

		&[disabled] {
			&:hover, &:focus {
				background: darken($clr-secondary, 20%);	
			}
		}
	}

	&.square {
		border-radius: 6px;
	}

	&.warning {
		background: $clr-warning;
		box-shadow: inset 0px -3px 0px 0px rgba(0,0,0,0.10);

		&:hover {
			background: darken($clr-warning, 5%);
		}
	}

	&.alert {
		background: $clr-big-red;
		box-shadow: inset 0px -3px 0px 0px rgba(0,0,0,0.10);

		&:hover {
			background: darken($clr-big-red, 5%);
		}
	}

	&.inverse {
		background: $clr-white;
		border: none;
		color: $clr-secondary;

		&:hover {
			background: $clr-secondary;
			color: $clr-white;
		}
	}

	&.white {
		background: $clr-white;
		color: $clr-secondary;
	}

	&:focus {
		outline: none;
	}

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none;   /* Chrome/Safari/Opera */
	-khtml-user-select: none;    /* Konqueror */
	-moz-user-select: none;      /* Firefox */
	-ms-user-select: none;       /* Internet Explorer/Edge */
	user-select: none;  
}