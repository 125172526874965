#why-zippgo {
	background: $clr-off-white;

	.hero {
		
		background-image: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url('/assets/img/hero-van-house3-cropped.jpg');
		background-position: center, center;
		text-align: center;
		
		@include breakpoint(retina) {
			background-image: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url('/assets/img/hero-van-house3-cropped@2x.jpg');
		}

		flex-direction: column;
		justify-content: center;
		align-items: center

		h1 {
			color: $clr-white;
			font-size: 2.6em;
			text-align: center;
		}

		.columns {
			text-align: center;
		}
	}

	.section {
		h2 {
			font-size: 2em;
			font-weight: normal;
			margin-bottom: 10px;
			color: #000;

			@include breakpoint(small only) {
				padding: 20px;
				margin-bottom: 10px;
			}
		}

		@include breakpoint(small only) {
			padding: 30px 20px;
		}
		text-align: center;

		p {
			color: $clr-dulled;
		}

		&#beats-cardboard {
			padding: 20px;
		}

		#why-reasons {
			margin-top: 10px;
			padding: 40px;

			.columns {
				.row {
					margin: 0px;
				}
			}

			p {
				color: $clr-text;
				text-align: justify;
				padding: 0px 40px;
				line-height: 1.4em;
				font-size: 1em;

				@include breakpoint (medium-landscape down) {
					padding: 0px 10px;
					text-align: center;
				}

				@include breakpoint(medium only) {
					text-align: left;
				}
			}

			i {
				font-size: 5em;
				color: $clr-primary;
				display: block;
				margin-bottom: 20px;

				@include breakpoint(medium only) {
					margin-top: 20px;
				}
			}

			h4 {
				font-size: 1em;
				font-weight: bold;
				text-transform: uppercase;
				color: #000;

				@include breakpoint(medium only) {
					margin-bottom: 20px;
				}
			}

			.checklist {
				margin-top: 30px;
				margin-left: 40px;
				text-align: left;

				@include breakpoint(small only) {
					margin-bottom: 50px;
				}

				@include breakpoint(medium only) {
					margin-top: 0px;
					margin-bottom: 0px;
				}

				li {
					color: #000;
					font-weight: bold;

					&:before {
						color: $clr-primary;
					}
				}
			}
		}

		&.testimonials {
			.testimonial {
				border-left: none;
				padding-top: 20px;
				padding-bottom: 20px;
			}
		}

		&.bottom {
			text-align: left;

			.button {
				padding-left: 2.5em;
				padding-right: 2.5em;
				
				@include breakpoint(medium up) {
					margin-right: 20px;
				}
			}

			.money-back {
				width: 200px;
			}
		}
	}
}