#locations {

	.areas #area-list {
		height: 325px;

		@include breakpoint(medium only) {
			height: 500px;
		}

		@include breakpoint(small only) {
			max-height: 800px;
			height: 800px;
		}
	}

	#locations-content {
		background-image: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url('/assets/img/locations-image.png');
		background-repeat: no-repeat, no-repeat;
		background-size: cover, cover;
		padding: 50px 20px;
		min-height: 50vh;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		.form-error {
			display: block;
		}

		button {
			min-width: 161px;
			text-align: center;
		}
	}

	.order-box {
		border-radius: 10px;
	}

	@include breakpoint(medium down) {
		.order-box {
			.row {
				margin: 0px;
			}

			.button {
				width: 100%;
			}
		}
	}

	@include breakpoint(medium only) {
		.order-box {
			max-width: 80vw;
			margin-left: auto;
			margin-right: auto;
		}
	}

	@include breakpoint(small only) {
		.order-box {
			padding-top: 10px;
			h2 {
				margin-top: 0px;
			}
		}
	}
}