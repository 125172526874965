ul {
	&.checklist {
		padding-left: 0px;
		margin-left: 0px;
		
		li {
			list-style: none;
			margin-bottom: 5px;

			@include breakpoint(small only) {
				margin-bottom: 15px;
			}

			&:before {
				@include fa-icon();
				content: $fa-var-check;
				margin-right: 10px;
			}
		}

		&.green li:before {
			color: $clr-primary;
		}
	}
}

ol {
	li {
		margin-bottom: 10px;
	}
}