#city {
	background: $clr-off-white;

	.smaller-row {
		width: 65rem;
	}

	.section {
		padding: 30px;

		h2 {
			font-size: 2em;
		}
	}

	.hero {

		//height: 400px;

		background-image: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url('/assets/img/hero-home.png');
		background-position: center, center;
		text-align: center;
		
		@include breakpoint(retina) {
			background-image: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url('/assets/img/hero-home@2x.png');
		}

		.columns {
			display: flex;
			flex-direction: column;
			align-items: center;
			color: $clr-white;

			h1 {
				text-align: center;
				//font-size: 2em;
			}

			.small {
				font-size: 0.8em;
				color: $clr-white;
			}
		}

		.button {
			font-size: 1em;

			@include breakpoint(small only) {
				font-size: 1.6em;
			}
		}
	}

	#city-tab-bar {
		background: $clr-primary;
		ul {
			width: 100%;
			display: flex;
			justify-content: center;
			background: none;
			border: none;
			align-items: stretch;
			margin: 0px;

			li {
				flex-grow: 1;
				background: none;
				border: none;
				text-align: center;
				font-size: 1.3em;
				font-weight: 400;
				display: flex;
				align-items: stretch;
				position: relative;

				@include breakpoint(medium up) {
					&.is-active:after {
						bottom: 0px;
						left: 50%;
						border: solid transparent;
						content: " ";
						height: 0;
						width: 0;
						position: absolute;
						pointer-events: none;
						border-color: rgba(255, 255, 255, 0);
						border-bottom-color: #fff;
						border-width: 20px;
						transform: translateX(-50%);
					}
				}

				&, a {
					&:hover,&:focus,&.is-active,&:last-child {
						outline: none!important;
						background: none!important;
						font-weight: normal!important;
					}
				}

				a {
					color: $clr-white;
					flex-grow: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 35px;
				}

				@include breakpoint(medium up) {

				}
			}

			@include breakpoint(small only) {
				flex-direction: column;

				li {
					a {
						padding: 20px;
					}
				}
			}
		}
	}

	.tabs-content {
		border: none;
		background: none;

		.tabs-panel {
			border: none;
			padding: 0px;
		}

		.footnote {
			color: $clr-dulled;
			font-size: 0.9em;
		}

		h2 {
			margin-bottom: 20px;
		}

		.checklist {
			text-align: left;
			font-size: 0.9em;

			li {
				margin-bottom: 10px;
			}
		}
	}

	.map-holder {
		@include breakpoint(medium up) {
			padding: 0px 30px;
		}
	}

	h3 {
		margin-top: 20px;
	}

	.our-hours-days {
		font-weight: bold;
	}

	#how {
		p{
			margin-bottom: 40px;
		}

		.how-image {
			@include breakpoint(medium up) {
				max-width: 50%;
			}

			margin-bottom: 60px;
		}
	}

	#testimonials {

		h2 {
			margin-bottom: 30px;
		}

		.testimonial {
			text-align: left;
			border: 2px solid #7d7d7d;
			border-radius: 10px;
			position: relative;
			align-items: flex-start;
			padding: 10px 30px;
			padding-bottom: 30px;

			margin-bottom: 80px;

			&:after, &:before {
				top: 100%;
				left: 10%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
			}

			&:after {
				border-color: rgba(255, 255, 255, 0);
				border-top-color: #fff;
				border-width: 15px;
				margin-left: -15px;
			}

			&:before {
				border-color: rgba(125, 125, 125, 0);
				border-top-color: #7d7d7d;
				border-width: 18px;
				margin-left: -18px;
			}

			p {
				font-size: 1em;
				padding: 0px;
				width: 100%;
			}

			.author {
				text-align: left;

				b {
					margin-left: 10px;
				}

				.gold-star {
					font-size: 1em;
				}
			}
		}
	}

	#resources {
		.resource {
			align-items: flex-start;
			text-align: left;
			margin-bottom: 20px;

			h3 {
				text-transform: uppercase;
				font-size: 1.2em;
				font-weight: bold;
			}
		}
	}
}