.why {
	h1, p {
		text-align: center;
	}

	h2 {
		margin-bottom: 30px;
		text-align: center;
		font-size: 2em;
	}

	padding: 76px 50px;
	background: $clr-highlight;
	color: $clr-white;

	@include breakpoint(small only) {
		padding: 20px 5px;
	}

	p {
		padding: 30px;
		margin-bottom: 49px;

		@include breakpoint(medium down) {
			padding: 5px;
			text-align: left;
			margin-bottom: 20px;
		}
	}

	.button {
		margin-top: 40px;
		font-size: 1.5em;

		@include breakpoint(small only) {	
			width: 100%;
		}
	}

	.checklist {
		@include breakpoint(small only) {
			padding-left: 10px;
			padding-right: 10px;
			font-weight: bold;
		}

		li {
			&:before {
				color: $clr-white;
			}
		}
	}
}