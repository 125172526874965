.tabs {
	margin: 0 auto;
	background: $clr-off-white;
	border-radius: 100px;
	border: 3px solid $clr-off-white;
	overflow: hidden;
	margin-bottom: 40px;

	li {
		background: none;

		a, &:hover a, a:hover {
			color: $clr-dulled;
			padding: 0.8rem 2.5rem;
			background: none;
			font-size: 0.85em;

			@include breakpoint(small only) {
				padding: 0.8rem 2rem;	
			}
		}

		&.is-active a {
			color: darken($clr-highlight, 10%);
			background: #fff;
			font-weight: bold;
		}
	}
}