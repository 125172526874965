#thank-you {
	background: $clr-off-white;

	#thank-you-box {
		margin: 50px auto;
		background: $clr-white;
		border: 2px solid #7D7D7D;
		border-radius: 10px;
		padding: 40px;

		strong {
			color: #000;
		}

		h1 {
			margin-bottom: 30px;
			color: #000;
		}

		.contact-options {
			margin-top: 20px;

			.columns {
				display: flex;
				align-items: center;
				margin-top: 10px;
			}

			i {
				padding: 15px;
				border: 3px solid #E8EBED;
				border-radius: 100px;
				margin-right: 15px;

				&.fa-comments {
					color: #D75252;
				}

				&.fa-envelope {
					color: $clr-secondary;
				}
			}

			span {
				flex-shrink: 1;
			}
		}
	}

	.checklist {
		li:before {
			color: $clr-primary;
		}
	}

	.moving-co {
		margin-bottom: 50px;

		h2, p {
			color: #000;
		}

		.end {
			padding-left: 20px;
		}
	}
}