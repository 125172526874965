#faq {
	background: $clr-off-white;

	.hero {
		//height: 400px;
		
		background-image: url('/assets/img/hero-car.png');

		@include breakpoint(retina) {
			background-image: url('/assets/img/hero-car@2x.png');
		}

		@include breakpoint(small only) {
			//display: none;
		}

		margin-bottom: 50px;
	}

	#content {
		padding-bottom: 30px;

		@include breakpoint(small only) {
			#page-content {
				padding: 20px;
			}
		}
	}

	.accordion {
		background: none;

		.accordion-item {
			margin-bottom: 20px;
			/* Rectangle 34: */
			background: none;

			overflow: hidden;
			border: 2px solid #E8EBED;
			border-radius: 10px;

			h2 {
				font-size: 1em;
				margin: 0px;
				padding: 0px;
			}

			.accordion-title {
				color: #000;
				font-size: 1.3em;
				background: $clr-white;
				border: none;

				padding-right: 40px;

				&:before {
					@include fa-icon();
					content: $fa-var-angle-down;
					font-size: 1.3em;
					margin-top: 0px;
					transform: translateY(-50%);
				}

				@include breakpoint(small only) {
					font-size: 1.1em;
				}
			}

			.accordion-content {
				background: $clr-white;
				color: $clr-secondary;
				border-top: none;
				padding: 30px 40px;
				border-radius: 0px 0px 10px 10px;
				border: none;

				@include breakpoint(small only) {
					padding: 15px 20px;
				}
			}

			&.is-active {
				border: none;

				.accordion-title {
					border-radius: 10px 10px 0px 0px;
					background: $clr-primary;
					border: 2px solid $clr-primary;
					color: $clr-white;
					//border-radius: 10px 10px 0px 0px;

					&:before {
						content: $fa-var-angle-up;
					}
				}

				.accordion-content {
					border: 2px solid #E8EBED;
					border-top: none;
				}
			}
		}
	}
}