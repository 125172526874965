html {
	display: flex;
	flex-direction: column;
}

body,html {
	flex-shrink: 0;
	flex-grow: 1;
	height: auto;
	width: 100%;
	min-height: 100%;
}

#page-content,.page-content {
	@include breakpoint(medium only) {
		padding: 0px 30px;
	}
}