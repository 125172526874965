$font-family: 'Montserrat', sans-serif;

body,p,h1,h2,h3,h4,h5,h6,a,.button,input,textarea {
	font-family: $font-family;
}

body {
	font-size: 18px;
	color: #4A4A4A;

    @include breakpoint(small only) {
        font-size: 14px;
    }
}

a {
	color: $clr-link;
    transition: color .2s ease-in-out;
}

h4,h5,h6 {
	font-weight: bold;
}

h1 {
	font-size: 2em;
}

h2 {
	font-size: 1.3em;
}

h3 {
	font-size: 1.3em;
}

h4 {
	font-size: 1em;
}

.text-dulled {
    color: $clr-dulled;
}

.page-head {
	color: #000;
	font-size: 1.66em;
	margin-bottom: 50px;
}

@include breakpoint(large up) {
	.text-large-right {
		text-align: right;
	}

	.text-large-left {
		text-align: left;
	}

	.text-large-center {
		text-align: center;
	}
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat-bold.eot');
    src: url('../fonts/montserrat-bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/montserrat-bold.woff2') format('woff2'),
    url('../fonts/montserrat-bold.woff') format('woff'),
    url('../fonts/montserrat-bold.ttf') format('truetype'),
    url('../fonts/montserrat-bold.svg#montserratbold') format('svg');
    font-weight: 700;
    font-style: normal;

}




@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat-light.eot');
    src: url('../fonts/montserrat-light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/montserrat-light.woff2') format('woff2'),
    url('../fonts/montserrat-light.woff') format('woff'),
    url('../fonts/montserrat-light.ttf') format('truetype'),
    url('../fonts/montserrat-light.svg#montserratlight') format('svg');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat-regular.eot');
    src: url('../fonts/montserrat-regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/montserrat-regular.woff2') format('woff2'),
    url('../fonts/montserrat-regular.woff') format('woff'),
    url('../fonts/montserrat-regular.ttf') format('truetype'),
    url('../fonts/montserrat-regular.svg#montserratregular') format('svg');
    font-weight: 400;
    font-style: normal;

}