#error-page {
	#content {
		margin-top: auto;
		margin-bottom: auto;

		padding-top: 40px;
		padding-bottom: 40px;

		h1,p {
			text-align: center;
			margin-bottom: 20px;
		}
	}
}