.mentions {
	width: 100%;
	background: $clr-off-white;
	.columns {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 25px;

		img {
			margin: 0 10px;
		}
	}
}