#header {
	@include breakpoint(medium up) {
		border-bottom: 2px solid #E8EBED;
	}
	
	.top-bar {
		background: $clr-secondary;
		color: $clr-white;
		font-size: 0.78em;

		.top-bar-left {
			a {
				margin: 0px 5px;
			}
		}

		a {
			color: $clr-white;
			font-weight: normal;
		}

		@include breakpoint(small only) {
			display: none;
		}
	}

	.header-menu {
		background: $clr-white;
		
		.columns {
			padding: 20px;

			&.logo {
				align-items: center;
				display: flex;

				@include breakpoint(large up) {
					padding-left: 80px;
				}

				&:last-child {
					padding-right: 80px;
				}
			}

			&.menu-button {

			}

			@include breakpoint(small only) {
				padding: 10px;
				text-align: center;
				padding-top: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.button {
			display: block;
			padding: 20px 35px;
			margin-left: 20px;
			border-width: 3px;
		}

		a:not(.button) {
			color: #2E2D3C;
			font-weight: normal;
		}
	}

	.order-now {
		display: none;

		@include breakpoint(small only) {
			width: 100%;
			display: block;
			padding: 0px;
			margin: 0px;
		}

		a {
			color: $clr-white;
			font-size: 0.8em;
			margin: 0px;
			border-radius: 0px;
			display: flex;
			align-items: center;
			justify-content: center;
			display: block;
			right: 0px;
			left: 0px;
			font-size: 1.3em;
			padding: 20px;
		}
	}

	@include breakpoint(medium down) {

		.header-menu {
			.columns:last-child {
				position: relative;
				padding: 2px;
				width: 100%;
				background: none;
			}
		}

		#main-menu {
			background: $clr-white;
			position: absolute;
			left: 0px;
			right: 0px;
			top: 0px;
			display: none;
			flex-direction: column;
			align-items: center;
			border-top: 3px solid $clr-off-white;
			padding-bottom: 30px;
			z-index: 99;

			&.open {
				display: flex;
			}

			li {
				order: 2;
			}

			li:last-child {
				order: 1;
				border-bottom: 2px solid $clr-off-white;
				align-self: stretch;
				padding: 20px;
				margin-bottom: 20px;

				.button {
					margin: 0px;
					width: 100%;
				}
			}
		}
	}

	@include breakpoint(medium-landscape only) {
		#main-menu {
			li {
				font-size: 0.9em;
			}
		}
	}

	@media #{breakpoint(medium only)} {

		.menu-button {
			display: flex;
			//align-items: center;
		}

		.menu-button a {
			margin-top: 7px;
			text-align: center;
			display: block;
		}

		#main-menu {
			left: auto;
			top: -20px;
			right: 6px;
			width: 300px;
			border-top: none;
			box-shadow: 0px 0px 4px 0px rgba(45,44,60,0.20);

			&:after {
				bottom: calc(100% - 10px);
				right: 17px;
				background: $clr-white;
				height: 20px;
				width: 20px;
				display: block;
				position: absolute;
				pointer-events: none;
				content: ' ';
				transform: rotate(45deg);
				z-index: 100;
				box-shadow: -2px -2px 2px 0px rgba(45,44,60,0.08);
			}
		}
	}
}