.hero {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	min-height: 30vw;
	height: auto;
	padding-top: 40px;
	padding-bottom: 40px;

	background-color: $clr-secondary;

	display: flex;


	align-items: center;

	h1 {
		margin-bottom: 30px;

		font-size: 2.6em;
		text-align: center;
		font-weight: 400;
		
		@include breakpoint(small only) {
			font-size: 2em;
			padding: 20px;
		}

		color: $clr-white;
	}

	@media #{breakpoint(medium up)} and #{breakpoint(medium-landscape down)}  {
		font-size: 0.8em;

		.button {
			padding: 20px 25px;
		}
	}

	@include breakpoint(small only) {
		.button {
			//width: 100%;
			font-size: 1em;
			padding: 20px 30px;
		}
	}

	.small {
		text-align: center;
	}
}