#moving {
	background: $clr-white;

	&.office-moving {
		background: $clr-off-white;
	}

	.hero {
		background-image: linear-gradient(270deg, rgba(0,0,0,0.00) 0%, fade-out(#000000, .25) 100%), url('/assets/img/hero-home.png');

		@include breakpoint(retina) {
			background-image: linear-gradient(270deg, rgba(0,0,0,0.00) 0%, fade-out(#000000, .25) 100%), url('/assets/img/hero-home@2x.png');
		}

		.columns {
			display: flex;
			flex-direction: column;
			align-items: center;
			color: $clr-white;

			h1 {
				text-align: center;
			}

			.small {
				font-size: 0.8em;
			}
		}

		.button {
			//font-size: 1.6em;
		}
	}

	.benefits {
		background: $clr-off-white;

		p.how {
			color: #000;
		}

		p:last-child {
			margin-top: 50px;
		}

		.benefit {
			background: $clr-white;
			display: flex;
			padding: 25px;
			margin: 20px;

			align-items: center;

			font-size: 1.5em;
			color: #000;

			span {
				flex-shrink: 1;
			}

			.benefit-img {
				width: 100px;
				margin-right: 30px;
				text-align: center;
			}
		}
	}
}