@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.2.4
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
body, p, h1, h2, h3, h4, h5, h6, a, .button, input, textarea {
  font-family: "Montserrat", sans-serif; }

body {
  font-size: 18px;
  color: #4A4A4A; }
  @media screen and (max-width: 39.9375em) {
    body {
      font-size: 14px; } }

a {
  color: #6BC3FE;
  transition: color .2s ease-in-out; }

h4, h5, h6 {
  font-weight: bold; }

h1 {
  font-size: 2em; }

h2 {
  font-size: 1.3em; }

h3 {
  font-size: 1.3em; }

h4 {
  font-size: 1em; }

.text-dulled {
  color: #999999; }

.page-head {
  color: #000;
  font-size: 1.66em;
  margin-bottom: 50px; }

@media screen and (min-width: 80em) {
  .text-large-right {
    text-align: right; }
  .text-large-left {
    text-align: left; }
  .text-large-center {
    text-align: center; } }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat-bold.eot");
  src: url("../fonts/montserrat-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-bold.woff2") format("woff2"), url("../fonts/montserrat-bold.woff") format("woff"), url("../fonts/montserrat-bold.ttf") format("truetype"), url("../fonts/montserrat-bold.svg#montserratbold") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat-light.eot");
  src: url("../fonts/montserrat-light.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-light.woff2") format("woff2"), url("../fonts/montserrat-light.woff") format("woff"), url("../fonts/montserrat-light.ttf") format("truetype"), url("../fonts/montserrat-light.svg#montserratlight") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat-regular.eot");
  src: url("../fonts/montserrat-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-regular.woff2") format("woff2"), url("../fonts/montserrat-regular.woff") format("woff"), url("../fonts/montserrat-regular.ttf") format("truetype"), url("../fonts/montserrat-regular.svg#montserratregular") format("svg");
  font-weight: 400;
  font-style: normal; }

.row {
  width: 80rem;
  max-width: 100%; }
  .row .row {
    max-width: 100%; }

.full-width {
  max-width: 100%;
  width: 100%; }

body {
  display: flex;
  flex-direction: column;
  background: #fff; }

.section {
  padding: 70px 30px;
  width: 100%; }
  .section.white {
    background: #fff; }

.bg-right {
  background-position: right; }

.bg-left {
  background-position: left; }

.content-padded.top #content {
  padding-top: 30px; }

.content-padded.bottom #content {
  padding-bottom: 50px; }

.button {
  border-radius: 100px;
  background: none;
  border: none;
  padding: 1.1em 2em;
  font-weight: bold;
  font-size: 1em;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }
  .button.default {
    border: 3px solid #67CE67;
    color: #2E2D3C; }
    .button.default:hover, .button.default:focus {
      background: #67CE67;
      color: #fff; }
  .button.success {
    background: #67CE67;
    box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 0.1); }
    .button.success[disabled]:hover, .button.success[disabled]:focus {
      background: #329d32; }
  .button.dark {
    background: #2E2D3C;
    box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 0.1); }
    .button.dark[disabled]:hover, .button.dark[disabled]:focus {
      background: #010102; }
  .button.square {
    border-radius: 6px; }
  .button.warning {
    background: #E67E22;
    box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 0.1); }
    .button.warning:hover {
      background: #d67118; }
  .button.alert {
    background: #f94e36;
    box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 0.1); }
    .button.alert:hover {
      background: #f8381d; }
  .button.inverse {
    background: #fff;
    border: none;
    color: #2E2D3C; }
    .button.inverse:hover {
      background: #2E2D3C;
      color: #fff; }
  .button.white {
    background: #fff;
    color: #2E2D3C; }
  .button:focus {
    outline: none; }

.hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 30vw;
  height: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #2E2D3C;
  display: flex;
  align-items: center; }
  .hero h1 {
    margin-bottom: 30px;
    font-size: 2.6em;
    text-align: center;
    font-weight: 400;
    color: #fff; }
    @media screen and (max-width: 39.9375em) {
      .hero h1 {
        font-size: 2em;
        padding: 20px; } }
  @media (min-width: 40em) and (max-width: 79.9375em) {
    .hero {
      font-size: 0.8em; }
      .hero .button {
        padding: 20px 25px; } }
  @media screen and (max-width: 39.9375em) {
    .hero .button {
      font-size: 1em;
      padding: 20px 30px; } }
  .hero .small {
    text-align: center; }

ul.checklist {
  padding-left: 0px;
  margin-left: 0px; }
  ul.checklist li {
    list-style: none;
    margin-bottom: 5px; }
    @media screen and (max-width: 39.9375em) {
      ul.checklist li {
        margin-bottom: 15px; } }
    ul.checklist li:before {
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      margin-right: 10px; }
  ul.checklist.green li:before {
    color: #67CE67; }

ol li {
  margin-bottom: 10px; }

label {
  font-size: 1em;
  font-weight: 300; }

input[type="text"], input[type="email"], input[type="date"], select, .wrap-dd-select, datepicker > input, textarea {
  background: #F4F5F7;
  box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: none;
  padding: 15px;
  height: auto;
  font-size: 1em;
  font-weight: normal;
  font-family: 'Montserrat', sans-serif;
  color: #2E2D3C; }
  input[type="text"]::placeholder, input[type="email"]::placeholder, input[type="date"]::placeholder, select::placeholder, .wrap-dd-select::placeholder, datepicker > input::placeholder, textarea::placeholder {
    color: #9B9B9B; }
  input[type="text"]:focus, input[type="email"]:focus, input[type="date"]:focus, select:focus, .wrap-dd-select:focus, datepicker > input:focus, textarea:focus {
    border: none;
    outline: none; }
  @media screen and (max-width: 39.9375em) {
    input[type="text"], input[type="email"], input[type="date"], select, .wrap-dd-select, datepicker > input, textarea {
      font-size: 1.2em; } }

input[type="date"] {
  min-height: 52px; }

select {
  cursor: pointer; }
  select option[placeholder] {
    color: #999999; }

.select-container {
  position: relative;
  width: 100%;
  display: block; }
  .select-container:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: ""; }
  .select-container input[type="date"]::-webkit-clear-button, .select-container input[type="date"]::-webkit-inner-spin-button, .select-container input[type="date"]::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    display: none; }

[data-abide-error] {
  border: none;
  border-radius: 6px;
  color: #4A4A4A; }

input[type="checkbox"]:focus {
  outline: none; }

.wrap-dd-select {
  font-weight: normal;
  color: #2E2D3C;
  width: 100%;
  height: 51px;
  padding-top: 12px;
  margin-bottom: 16px;
  line-height: 1.5em; }
  .wrap-dd-select .dropdown {
    display: none; }
  .wrap-dd-select.active {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: none; }
    .wrap-dd-select.active .dropdown {
      display: block; }
  .wrap-dd-select .dropdown {
    width: 100%;
    margin-left: 0px;
    margin-top: -0px;
    border: 0px;
    box-shadow: none;
    background: #fff;
    border-radius: 0px 0px 10px 10px;
    color: #2E2D3C;
    border: 2px solid #F4F5F7; }
    .wrap-dd-select .dropdown li .dropdown-item {
      padding: 8px 15px;
      box-shadow: none;
      color: #4A4A4A; }
    .wrap-dd-select .dropdown li.divider-label {
      padding-left: 10px;
      color: #999999; }
    .wrap-dd-select .dropdown li:first-child .dropdown-item {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px; }
    .wrap-dd-select .dropdown li:hover .dropdown-item {
      background: #F4F5F7; }

.ng-invalid > label .wrap-dd-select {
  background: rgba(236, 88, 64, 0.1); }

datepicker {
  font-size: 1em; }
  datepicker ._720kb-datepicker-calendar-day, datepicker ._720kb-datepicker-calendar-day._720kb-datepicker-active {
    background: transparent;
    transition: none; }
    datepicker ._720kb-datepicker-calendar-day:hover, datepicker ._720kb-datepicker-calendar-day._720kb-datepicker-active:hover {
      background: #67CE67;
      color: #fff; }
  datepicker ._720kb-datepicker-calendar-header:nth-child(odd), datepicker ._720kb-datepicker-calendar-header:nth-child(even) {
    background: #67CE67; }
  datepicker ._720kb-datepicker-calendar-header, datepicker ._720kb-datepicker-calendar-header a {
    font-weight: bold; }
  datepicker ._720kb-datepicker-calendar-header ._720kb-datepicker-calendar-years-pagination-pages {
    display: none; }
  datepicker ._720kb-datepicker-calendar-header ._720kb-datepicker-disabled {
    display: none; }
  datepicker select {
    background: #F4F5F7;
    box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    border: none;
    padding: 15px;
    height: auto;
    font-size: 1em;
    font-weight: normal;
    font-family: 'Montserrat', sans-serif;
    color: #2E2D3C; }
    datepicker select option {
      background: #F4F5F7;
      color: #2E2D3C; }

.rzslider {
  margin-bottom: 50px; }
  .rzslider .rz-ticks {
    top: 0px;
    right: 0px;
    bottom: 0px;
    height: 100%;
    height: 25px;
    border-radius: 25px;
    display: flex;
    border: 3px solid #F4F5F7; }
    .rzslider .rz-ticks .rz-tick {
      flex-grow: 1;
      position: static;
      margin: 0px;
      height: 100%;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0px;
      background: #fff;
      flex-shrink: 1;
      transform: none !important;
      border-right: 3px solid #F4F5F7;
      position: relative; }
      .rzslider .rz-ticks .rz-tick:first-child {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px; }
      .rzslider .rz-ticks .rz-tick:last-child {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border-right: none; }
      .rzslider .rz-ticks .rz-tick .rz-tick-value {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        flex-shrink: 1;
        font-size: 0.8em; }
      .rzslider .rz-ticks .rz-tick.rz-selected {
        background: #fff; }
      .rzslider .rz-ticks .rz-tick.rz-last-selected .rz-tick-value {
        position: absolute;
        background: #67CE67;
        color: #fff;
        font-size: 1em;
        border-radius: 25px;
        padding: 2px 10px;
        width: 120%;
        z-index: 10; }
  .rzslider .rz-bar {
    background: #F4F5F7;
    height: 25px;
    border-radius: 25px;
    border: 3px solid #F4F5F7; }
    .rzslider .rz-bar.rz-selection {
      display: none; }
  .rzslider .rz-pointer {
    top: -5px;
    background: none;
    width: 100px;
    height: 34px; }
    .rzslider .rz-pointer:after {
      display: none; }
    .rzslider .rz-pointer:focus {
      outline: none; }
  .rzslider .rz-bubble {
    bottom: -25px;
    color: #fff;
    background: #67CE67;
    border-radius: 25px;
    z-index: 2;
    padding: 3px 20px; }

.animate {
  opacity: 1; }

.animate.ng-hide-add, .animate.animate-show.ng-hide-remove {
  transition: all linear 0.5s; }

.animate.ng-hide {
  opacity: 0; }

.reveal {
  padding: 20px;
  border-radius: 10px;
  border: none;
  max-width: 100%; }
  .reveal h2, .reveal .modal-title {
    border-bottom: 2px solid #E8EBED;
    text-align: center;
    font-size: 2em;
    color: #4A4A4A;
    margin: -20px;
    margin-bottom: 20px;
    padding: 20px; }
    @media screen and (max-width: 39.9375em) {
      .reveal h2, .reveal .modal-title {
        font-size: 1.8em; } }
  .reveal:focus {
    outline: none; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      border-radius: 0px;
      max-height: 100vh;
      max-width: 100vw;
      overflow-y: auto; } }

.reveal-overlay {
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6); }

.close-button {
  display: block;
  font-size: 1.1em;
  color: #4A4A4A;
  border-radius: 50%;
  border: 3px solid #4A4A4A;
  padding: 3px;
  padding-top: 4px;
  width: 33px;
  height: 33px;
  text-align: center;
  transition: none; }
  .close-button:hover {
    color: #fff;
    background: #4A4A4A; }

@media screen and (max-width: 63.9375em) {
  html.is-reveal-open body {
    height: auto; } }

.tabs {
  margin: 0 auto;
  background: #F4F5F7;
  border-radius: 100px;
  border: 3px solid #F4F5F7;
  overflow: hidden;
  margin-bottom: 40px; }
  .tabs li {
    background: none; }
    .tabs li a, .tabs li:hover a, .tabs li a:hover {
      color: #999999;
      padding: 0.8rem 2.5rem;
      background: none;
      font-size: 0.85em; }
      @media screen and (max-width: 39.9375em) {
        .tabs li a, .tabs li:hover a, .tabs li a:hover {
          padding: 0.8rem 2rem; } }
    .tabs li.is-active a {
      color: #38aefe;
      background: #fff;
      font-weight: bold; }

.sweet-alert {
  font-family: "Montserrat", sans-serif;
  padding: 2em; }
  @media screen and (max-width: 39.9375em) {
    .sweet-alert {
      padding: 1em; }
      .sweet-alert p {
        font-size: 1em; }
      .sweet-alert h2 {
        font-size: 1.2em;
        line-height: 1em; } }
  .sweet-alert button {
    background: #67CE67 !important; }
    .sweet-alert button:hover {
      background: #329d32; }

.call-to-action {
  background: #6BC3FE;
  padding: 30px 20px; }
  .call-to-action .columns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .call-to-action .button {
    margin: 0px;
    display: block;
    font-size: 1.5em;
    padding: 0.8em 2em; }
    @media screen and (max-width: 39.9375em) {
      .call-to-action .button {
        margin-top: 20px; } }
  .call-to-action .cta-text {
    font-size: 1.8em;
    font-weight: bold;
    color: #fff; }
    @media screen and (max-width: 39.9375em) {
      .call-to-action .cta-text {
        text-align: center; } }

#box-sizes .columns {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center; }

.boxes {
  padding-bottom: 40px; }
  .boxes h2 {
    font-size: 2em; }
  .boxes .uses {
    margin-top: -30px;
    color: #4A4A4A;
    text-align: center;
    font-weight: bold; }

.mentions {
  width: 100%;
  background: #F4F5F7; }
  .mentions .columns {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px; }
    .mentions .columns img {
      margin: 0 10px; }

@media screen and (min-width: 40em) {
  #header {
    border-bottom: 2px solid #E8EBED; } }

#header .top-bar {
  background: #2E2D3C;
  color: #fff;
  font-size: 0.78em; }
  #header .top-bar .top-bar-left a {
    margin: 0px 5px; }
  #header .top-bar a {
    color: #fff;
    font-weight: normal; }
  @media screen and (max-width: 39.9375em) {
    #header .top-bar {
      display: none; } }

#header .header-menu {
  background: #fff; }
  #header .header-menu .columns {
    padding: 20px; }
    #header .header-menu .columns.logo {
      align-items: center;
      display: flex; }
      @media screen and (min-width: 80em) {
        #header .header-menu .columns.logo {
          padding-left: 80px; } }
      #header .header-menu .columns.logo:last-child {
        padding-right: 80px; }
    @media screen and (max-width: 39.9375em) {
      #header .header-menu .columns {
        padding: 10px;
        text-align: center;
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center; } }
  #header .header-menu .button {
    display: block;
    padding: 20px 35px;
    margin-left: 20px;
    border-width: 3px; }
  #header .header-menu a:not(.button) {
    color: #2E2D3C;
    font-weight: normal; }

#header .order-now {
  display: none; }
  @media screen and (max-width: 39.9375em) {
    #header .order-now {
      width: 100%;
      display: block;
      padding: 0px;
      margin: 0px; } }
  #header .order-now a {
    color: #fff;
    font-size: 0.8em;
    margin: 0px;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: block;
    right: 0px;
    left: 0px;
    font-size: 1.3em;
    padding: 20px; }

@media screen and (max-width: 63.9375em) {
  #header .header-menu .columns:last-child {
    position: relative;
    padding: 2px;
    width: 100%;
    background: none; }
  #header #main-menu {
    background: #fff;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    display: none;
    flex-direction: column;
    align-items: center;
    border-top: 3px solid #F4F5F7;
    padding-bottom: 30px;
    z-index: 99; }
    #header #main-menu.open {
      display: flex; }
    #header #main-menu li {
      order: 2; }
    #header #main-menu li:last-child {
      order: 1;
      border-bottom: 2px solid #F4F5F7;
      align-self: stretch;
      padding: 20px;
      margin-bottom: 20px; }
      #header #main-menu li:last-child .button {
        margin: 0px;
        width: 100%; } }

@media screen and (min-width: 64em) and (max-width: 79.9375em) {
  #header #main-menu li {
    font-size: 0.9em; } }

@media (min-width: 40em) and (max-width: 63.9375em) {
  #header .menu-button {
    display: flex; }
  #header .menu-button a {
    margin-top: 7px;
    text-align: center;
    display: block; }
  #header #main-menu {
    left: auto;
    top: -20px;
    right: 6px;
    width: 300px;
    border-top: none;
    box-shadow: 0px 0px 4px 0px rgba(45, 44, 60, 0.2); }
    #header #main-menu:after {
      bottom: calc(100% - 10px);
      right: 17px;
      background: #fff;
      height: 20px;
      width: 20px;
      display: block;
      position: absolute;
      pointer-events: none;
      content: ' ';
      transform: rotate(45deg);
      z-index: 100;
      box-shadow: -2px -2px 2px 0px rgba(45, 44, 60, 0.08); } }

#footer {
  background: #2E2D3C;
  line-height: 3em;
  margin-top: auto;
  padding: 60px; }
  @media screen and (max-width: 39.9375em) {
    #footer {
      padding: 20px 0px; } }
  #footer ul {
    padding-left: 0px;
    margin-left: 0px;
    font-weight: 200;
    flex-grow: 1; }
    @media screen and (max-width: 39.9375em) {
      #footer ul {
        display: flex;
        align-items: center;
        margin-bottom: 20px; }
        #footer ul a {
          text-align: center;
          align-items: center;
          justify-content: center;
          padding: 10px; } }
    @media screen and (min-width: 80em) {
      #footer ul li:last-child a {
        padding-bottom: 0px; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    #footer > .row > .columns {
      margin-bottom: 20px; } }
  #footer b {
    font-weight: bold;
    color: #fff; }
  #footer a, #footer a:hover, #footer a:focus {
    color: #fff; }
  @media screen and (min-width: 40em) {
    #footer a {
      padding-left: 0px; } }
  #footer p {
    color: #fff;
    font-weight: 200; }
  #footer .footer-social a {
    margin: 0 5px; }
  @media screen and (max-width: 39.9375em) {
    #footer {
      text-align: center; } }
  #footer .footer-copy {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start; }
    @media screen and (max-width: 63.9375em) {
      #footer .footer-copy {
        justify-content: center;
        align-items: center; } }
    #footer .footer-copy p {
      margin-bottom: 0px; }
  #footer > .row > .columns {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

html {
  display: flex;
  flex-direction: column; }

body, html {
  flex-shrink: 0;
  flex-grow: 1;
  height: auto;
  width: 100%;
  min-height: 100%; }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  #page-content, .page-content {
    padding: 0px 30px; } }

#home-hero {
  background-image: linear-gradient(270deg, transparent 0%, rgba(0, 0, 0, 0.75) 100%), url("/assets/img/hero-home.png"); }
  @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    #home-hero {
      background-image: linear-gradient(270deg, transparent 0%, rgba(0, 0, 0, 0.75) 100%), url("/assets/img/hero-home@2x.png"); } }
  @media screen and (max-width: 39.9375em) {
    #home-hero {
      background-position: 90%;
      background-size: cover; } }
  #home-hero .columns {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff; }
    #home-hero .columns h1 {
      text-align: center;
      font-size: 2em; }
    #home-hero .columns .small {
      font-size: 0.8em;
      color: #fff; }
  #home-hero .button {
    font-size: 1.6em; }
    @media screen and (max-width: 39.9375em) {
      #home-hero .button {
        font-size: 1.6em; } }

#home h2 {
  font-size: 2em; }

#home .boxes {
  background: #F4F5F7; }

#home .how {
  padding-top: 76px;
  font-weight: 200; }
  #home .how h2 {
    text-align: center; }
  @media screen and (max-width: 39.9375em) {
    #home .how {
      padding-top: 40px; } }
  #home .how .columns {
    text-align: center;
    padding: 25px 40px; }
    @media screen and (max-width: 39.9375em) {
      #home .how .columns {
        padding: 15px 30px; } }
    #home .how .columns img {
      margin-bottom: 30px;
      height: 202px;
      width: 202px;
      border: 6px solid #FFFFFF;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
      border-radius: 50%; }
      @media screen and (max-width: 39.9375em) {
        #home .how .columns img {
          margin-bottom: 15px; } }
  #home .how .note .columns {
    font-size: 0.65em;
    color: #999999;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px; }
    @media screen and (max-width: 39.9375em) {
      #home .how .note .columns {
        font-size: 0.8em; } }

#home .checklist {
  padding-left: 50px; }
  @media screen and (max-width: 39.9375em) {
    #home .checklist {
      padding-left: 10px;
      font-weight: bold; } }

.areas {
  color: #fff; }
  @media screen and (min-width: 40em) {
    .areas {
      background: #67CE67; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .areas .row {
      padding: 20px; } }
  .areas .row {
    width: 1440px; }
  .areas h2 {
    color: #fff; }
  .areas h3 {
    color: #000;
    margin-bottom: 15px; }
  .areas .columns {
    padding: 80px 20px; }
    @media screen and (min-width: 64em) {
      .areas .columns:first-child {
        padding-left: 50px; }
      .areas .columns:last-child {
        padding-right: 50px; } }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      .areas .columns {
        padding: 40px 20px; } }
    @media screen and (max-width: 39.9375em) {
      .areas .columns {
        padding: 10px; } }
    @media screen and (max-width: 39.9375em) {
      .areas .columns:first-child {
        padding: 20px;
        background: #67CE67; } }
  .areas .tabs {
    margin-bottom: 20px;
    background: none;
    border: none; }
    @media screen and (max-width: 39.9375em) {
      .areas .tabs {
        margin-bottom: 10px; } }
    .areas .tabs a {
      color: rgba(0, 0, 0, 0.3);
      padding: 10px 5px;
      margin: 0px 10px;
      font-size: 1em; }
    .areas .tabs li:first-child a {
      margin-left: 0px; }
    .areas .tabs li:hover {
      background: none; }
    .areas .tabs .is-active a, .areas .tabs a:hover {
      background: none;
      color: #fff;
      border-bottom: 2px solid #fff; }
  .areas .tabs-content {
    background: none;
    border: none; }
    .areas .tabs-content .tabs-panel.is-active {
      max-height: 200px;
      flex-shrink: 1;
      background: none;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 0px; }
      .areas .tabs-content .tabs-panel.is-active a {
        display: block;
        width: 25%;
        margin-bottom: 10px;
        color: #fff; }
        .areas .tabs-content .tabs-panel.is-active a:hover {
          color: #fff; }
  .areas #area-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    height: 525px;
    max-height: 525px; }
    .areas #area-list li {
      list-style: none;
      line-height: 1.65em;
      margin-right: 50px;
      color: rgba(255, 255, 255, 0.8); }
      .areas #area-list li a {
        color: #fff; }
        .areas #area-list li a:hover {
          color: #2E2D3C; }
    @media screen and (max-width: 39.9375em) {
      .areas #area-list {
        max-height: 825px;
        height: 825px; }
        .areas #area-list li {
          margin-right: 30px; } }
    @media screen and (min-width: 64em) and (max-width: 79.9375em) {
      .areas #area-list {
        max-height: 670px;
        height: 670px; } }
  .areas .area-note {
    padding-left: 20px;
    font-size: 0.8em; }
  .areas .order-button {
    margin-top: 30px;
    font-size: 1.7em;
    padding: 1em 1.8em; }
    @media screen and (max-width: 39.9375em) {
      .areas .order-button {
        width: 100%; } }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      .areas .order-button {
        width: 400px;
        display: block;
        margin-left: auto;
        margin-right: auto; } }
  .areas .quote-box {
    background: #fff;
    border-radius: 10px;
    padding: 20px; }
    @media screen and (max-width: 39.9375em) {
      .areas .quote-box {
        padding: 10px; } }
    @media screen and (min-width: 64em) and (max-width: 79.9375em) {
      .areas .quote-box {
        margin-top: 30px; } }
    .areas .quote-box input.ng-touched:not(.ng-pristine):not(:focus).ng-invalid:not(:focus), .areas .quote-box input.ng-touched:not(.ng-pristine):not(:focus).ng-invalid-cc-number-type {
      background-color: rgba(236, 88, 64, 0.1);
      border-color: #ec5840; }
    .areas .quote-box .quote-submitted {
      color: #67CE67;
      font-weight: bold;
      font-size: 0.8em;
      margin-bottom: 10px; }

#thank-you {
  background: #F4F5F7; }
  #thank-you #thank-you-box {
    margin: 50px auto;
    background: #fff;
    border: 2px solid #7D7D7D;
    border-radius: 10px;
    padding: 40px; }
    #thank-you #thank-you-box strong {
      color: #000; }
    #thank-you #thank-you-box h1 {
      margin-bottom: 30px;
      color: #000; }
    #thank-you #thank-you-box .contact-options {
      margin-top: 20px; }
      #thank-you #thank-you-box .contact-options .columns {
        display: flex;
        align-items: center;
        margin-top: 10px; }
      #thank-you #thank-you-box .contact-options i {
        padding: 15px;
        border: 3px solid #E8EBED;
        border-radius: 100px;
        margin-right: 15px; }
        #thank-you #thank-you-box .contact-options i.fa-comments {
          color: #D75252; }
        #thank-you #thank-you-box .contact-options i.fa-envelope {
          color: #2E2D3C; }
      #thank-you #thank-you-box .contact-options span {
        flex-shrink: 1; }
  #thank-you .checklist li:before {
    color: #67CE67; }
  #thank-you .moving-co {
    margin-bottom: 50px; }
    #thank-you .moving-co h2, #thank-you .moving-co p {
      color: #000; }
    #thank-you .moving-co .end {
      padding-left: 20px; }

#pricing {
  background: #F4F5F7; }
  #pricing .hero {
    width: 100%;
    margin-bottom: 50px; }
  #pricing #content {
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media screen and (max-width: 39.9375em) {
      #pricing #content #page-content {
        padding: 10px; } }
  #pricing #box-pricing-table .checklist li:before, #pricing.moving-pricing .checklist li:before, #pricing.packing-pricing .checklist li:before {
    color: #67CE67; }
  #pricing .hero {
    background-image: linear-gradient(rgba(46, 45, 60, 0.7), rgba(46, 45, 60, 0.7)), url("/assets/img/hero-pricing.jpg");
    background-position: top center, top center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover; }
    #pricing .hero .button {
      align-self: center;
      margin: 0 auto; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      #pricing .hero {
        background-image: linear-gradient(rgba(46, 45, 60, 0.7), rgba(46, 45, 60, 0.7)), url("/assets/img/hero-pricing@2x.jpg"); } }
  #pricing.moving-pricing #content .columns {
    justify-content: center;
    flex-direction: column;
    display: flex;
    align-items: flex-start; }
    #pricing.moving-pricing #content .columns.product-image {
      align-items: center; }
  #pricing.moving-pricing #content .hero h1 {
    text-align: center;
    width: 100%;
    color: #fff; }
  #pricing.moving-pricing #content #supplies-list {
    padding: 0px 30px; }
  @media screen and (max-width: 39.9375em) {
    #pricing.moving-pricing #content .product > .row {
      margin-left: 0px;
      margin-right: 0px; } }
  @media screen and (max-width: 79.9375em) {
    #pricing.moving-pricing #content .product .button {
      padding: 1em 2em; } }
  @media screen and (max-width: 39.9375em) {
    #pricing.moving-pricing #content .product .button {
      display: block;
      margin-left: auto;
      margin-right: auto; } }
  #pricing.box-pricing #content {
    background: #fff;
    width: 100%;
    max-width: 100%;
    padding-bottom: 0px; }
    #pricing.box-pricing #content .boxes {
      background: #F4F5F7;
      padding-top: 40px;
      margin-top: 60px; }
    @media screen and (min-width: 40em) {
      #pricing.box-pricing #content .hero {
        margin-bottom: -175px;
        min-height: 350px;
        height: 350px;
        align-items: flex-start; }
        #pricing.box-pricing #content .hero h1 {
          margin: 0 auto;
          margin-top: 60px;
          color: #fff;
          font-size: 2.6em;
          font-weight: 400;
          text-align: center; } }
    @media screen and (max-width: 39.9375em) {
      #pricing.box-pricing #content .hero h1 {
        width: 100%; } }
    #pricing.box-pricing #content .why {
      width: 100%;
      margin-top: 50px; }
    #pricing.box-pricing #content .box-list {
      display: flex;
      justify-content: space-around;
      width: 80rem;
      max-width: 100%;
      margin: 0 auto;
      overflow: hidden; }
      @media screen and (max-width: 79.9375em) {
        #pricing.box-pricing #content .box-list {
          flex-wrap: wrap; } }
      @media (min-width: 40em) and (max-width: 79.9375em) {
        #pricing.box-pricing #content .box-list {
          padding: 20px;
          padding-top: 0px; } }
    #pricing.box-pricing #content .package-holder {
      flex-shrink: 1;
      flex-grow: 1;
      width: auto;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      margin: 15px;
      margin-top: 0px;
      width: calc(20% - 30px); }
      @media screen and (max-width: 39.9375em) {
        #pricing.box-pricing #content .package-holder {
          width: 100%; } }
      @media (min-width: 40em) and (max-width: 79.9375em) {
        #pricing.box-pricing #content .package-holder {
          margin: 10px;
          margin-top: 0px;
          margin-bottom: 20px;
          width: calc(50% - 20px);
          flex-grow: 0; } }
      #pricing.box-pricing #content .package-holder:nth-child(6) {
        display: none; }
        @media (min-width: 40em) and (max-width: 79.9375em) {
          #pricing.box-pricing #content .package-holder:nth-child(6) {
            display: flex; } }
    #pricing.box-pricing #content .package {
      flex-shrink: 1;
      width: 100%;
      text-align: center;
      background: #fff;
      color: #2E2D3C;
      line-height: 1.8em;
      border-radius: 6px;
      overflow: hidden;
      transition: .3s ease-in-out box-shadow;
      display: flex;
      align-items: stretch;
      flex-direction: column;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3); }
      #pricing.box-pricing #content .package .package-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #67CE67;
        padding: 20px;
        color: #fff;
        height: 175px; }
        @media screen and (max-width: 39.9375em) {
          #pricing.box-pricing #content .package .package-top {
            height: auto; } }
      #pricing.box-pricing #content .package h2 {
        font-size: 2.3em;
        word-wrap: nowrap; }
        @media screen and (max-width: 39.9375em) {
          #pricing.box-pricing #content .package h2 {
            font-size: 2.6em; } }
      #pricing.box-pricing #content .package h3 {
        text-transform: uppercase;
        font-size: 1.2em;
        word-wrap: nowrap; }
        @media screen and (max-width: 39.9375em) {
          #pricing.box-pricing #content .package h3 {
            font-size: 1.6em; } }
      #pricing.box-pricing #content .package .package-section {
        border-bottom: 1px solid rgba(46, 45, 60, 0.3);
        text-align: center;
        padding: 10px;
        font-size: 0.9em; }
        @media screen and (max-width: 39.9375em) {
          #pricing.box-pricing #content .package .package-section {
            font-size: 1.3em; } }
      #pricing.box-pricing #content .package .package-count {
        color: #6BC3FE;
        font-weight: bold; }
      #pricing.box-pricing #content .package .package-short-description, #pricing.box-pricing #content .package .package-description {
        font-size: 0.8em; }
      #pricing.box-pricing #content .package .package-description {
        padding: 20px 15px;
        line-height: 1.8em;
        text-align: left; }
        #pricing.box-pricing #content .package .package-description ul {
          margin: 0px;
          padding: 0px; }
          #pricing.box-pricing #content .package .package-description ul.checklist li:before {
            color: #67CE67; }
        @media screen and (max-width: 39.9375em) {
          #pricing.box-pricing #content .package .package-description {
            font-size: 1.2em; } }
      #pricing.box-pricing #content .package .package-order-now {
        padding: 20px 15px;
        margin-top: auto;
        border-top: 1px solid rgba(46, 45, 60, 0.3); }
        @media screen and (max-width: 39.9375em) {
          #pricing.box-pricing #content .package .package-order-now {
            padding: 15px; } }
      #pricing.box-pricing #content .package .button {
        display: block;
        background: #f94e36;
        color: #fff;
        margin-bottom: 0px;
        font-size: 1.1em;
        text-transform: uppercase;
        padding: 1em 1em; }
        @media screen and (max-width: 39.9375em) {
          #pricing.box-pricing #content .package .button {
            font-size: 1.4em;
            padding: 0.8em 1.5em; } }
        @media (min-width: 40em) and (max-width: 79.9375em) {
          #pricing.box-pricing #content .package .button {
            display: inline-block;
            padding: 1em 2em; } }

.product {
  background: #FFFFFF;
  border: 2px solid #7D7D7D;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 30px; }
  .product .product-image {
    display: flex;
    align-items: center;
    justify-content: center; }
  .product h3 {
    font-size: 1.35em;
    font-weight: bold;
    color: #000;
    margin-bottom: 0px; }
  .product .product-price {
    color: #67CE67;
    font-size: 1.35em;
    margin-bottom: 20px;
    display: block; }
  .product .product-description {
    font-weight: 200;
    font-size: 1em; }
  .product .button {
    font-size: 1.5em;
    padding: 1em 3em;
    margin-top: 10px; }

#faq {
  background: #F4F5F7; }
  #faq .hero {
    background-image: url("/assets/img/hero-car.png");
    margin-bottom: 50px; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      #faq .hero {
        background-image: url("/assets/img/hero-car@2x.png"); } }
  #faq #content {
    padding-bottom: 30px; }
    @media screen and (max-width: 39.9375em) {
      #faq #content #page-content {
        padding: 20px; } }
  #faq .accordion {
    background: none; }
    #faq .accordion .accordion-item {
      margin-bottom: 20px;
      /* Rectangle 34: */
      background: none;
      overflow: hidden;
      border: 2px solid #E8EBED;
      border-radius: 10px; }
      #faq .accordion .accordion-item h2 {
        font-size: 1em;
        margin: 0px;
        padding: 0px; }
      #faq .accordion .accordion-item .accordion-title {
        color: #000;
        font-size: 1.3em;
        background: #fff;
        border: none;
        padding-right: 40px; }
        #faq .accordion .accordion-item .accordion-title:before {
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "";
          font-size: 1.3em;
          margin-top: 0px;
          transform: translateY(-50%); }
        @media screen and (max-width: 39.9375em) {
          #faq .accordion .accordion-item .accordion-title {
            font-size: 1.1em; } }
      #faq .accordion .accordion-item .accordion-content {
        background: #fff;
        color: #2E2D3C;
        border-top: none;
        padding: 30px 40px;
        border-radius: 0px 0px 10px 10px;
        border: none; }
        @media screen and (max-width: 39.9375em) {
          #faq .accordion .accordion-item .accordion-content {
            padding: 15px 20px; } }
      #faq .accordion .accordion-item.is-active {
        border: none; }
        #faq .accordion .accordion-item.is-active .accordion-title {
          border-radius: 10px 10px 0px 0px;
          background: #67CE67;
          border: 2px solid #67CE67;
          color: #fff; }
          #faq .accordion .accordion-item.is-active .accordion-title:before {
            content: ""; }
        #faq .accordion .accordion-item.is-active .accordion-content {
          border: 2px solid #E8EBED;
          border-top: none; }

#why-zippgo {
  background: #F4F5F7; }
  #why-zippgo .hero {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/assets/img/hero-van-house3-cropped.jpg");
    background-position: center, center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center h1;
      align-items-color: #fff;
      align-items-font-size: 2.6em;
      align-items-text-align: center; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      #why-zippgo .hero {
        background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/assets/img/hero-van-house3-cropped@2x.jpg"); } }
    #why-zippgo .hero .columns {
      text-align: center; }
  #why-zippgo .section {
    text-align: center; }
    #why-zippgo .section h2 {
      font-size: 2em;
      font-weight: normal;
      margin-bottom: 10px;
      color: #000; }
      @media screen and (max-width: 39.9375em) {
        #why-zippgo .section h2 {
          padding: 20px;
          margin-bottom: 10px; } }
    @media screen and (max-width: 39.9375em) {
      #why-zippgo .section {
        padding: 30px 20px; } }
    #why-zippgo .section p {
      color: #999999; }
    #why-zippgo .section#beats-cardboard {
      padding: 20px; }
    #why-zippgo .section #why-reasons {
      margin-top: 10px;
      padding: 40px; }
      #why-zippgo .section #why-reasons .columns .row {
        margin: 0px; }
      #why-zippgo .section #why-reasons p {
        color: #4A4A4A;
        text-align: justify;
        padding: 0px 40px;
        line-height: 1.4em;
        font-size: 1em; }
        @media screen and (max-width: 79.9375em) {
          #why-zippgo .section #why-reasons p {
            padding: 0px 10px;
            text-align: center; } }
        @media screen and (min-width: 40em) and (max-width: 63.9375em) {
          #why-zippgo .section #why-reasons p {
            text-align: left; } }
      #why-zippgo .section #why-reasons i {
        font-size: 5em;
        color: #67CE67;
        display: block;
        margin-bottom: 20px; }
        @media screen and (min-width: 40em) and (max-width: 63.9375em) {
          #why-zippgo .section #why-reasons i {
            margin-top: 20px; } }
      #why-zippgo .section #why-reasons h4 {
        font-size: 1em;
        font-weight: bold;
        text-transform: uppercase;
        color: #000; }
        @media screen and (min-width: 40em) and (max-width: 63.9375em) {
          #why-zippgo .section #why-reasons h4 {
            margin-bottom: 20px; } }
      #why-zippgo .section #why-reasons .checklist {
        margin-top: 30px;
        margin-left: 40px;
        text-align: left; }
        @media screen and (max-width: 39.9375em) {
          #why-zippgo .section #why-reasons .checklist {
            margin-bottom: 50px; } }
        @media screen and (min-width: 40em) and (max-width: 63.9375em) {
          #why-zippgo .section #why-reasons .checklist {
            margin-top: 0px;
            margin-bottom: 0px; } }
        #why-zippgo .section #why-reasons .checklist li {
          color: #000;
          font-weight: bold; }
          #why-zippgo .section #why-reasons .checklist li:before {
            color: #67CE67; }
    #why-zippgo .section.testimonials .testimonial {
      border-left: none;
      padding-top: 20px;
      padding-bottom: 20px; }
    #why-zippgo .section.bottom {
      text-align: left; }
      #why-zippgo .section.bottom .button {
        padding-left: 2.5em;
        padding-right: 2.5em; }
        @media screen and (min-width: 40em) {
          #why-zippgo .section.bottom .button {
            margin-right: 20px; } }
      #why-zippgo .section.bottom .money-back {
        width: 200px; }

#moving {
  background: #fff; }
  #moving.office-moving {
    background: #F4F5F7; }
  #moving .hero {
    background-image: linear-gradient(270deg, transparent 0%, rgba(0, 0, 0, 0.75) 100%), url("/assets/img/hero-home.png"); }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      #moving .hero {
        background-image: linear-gradient(270deg, transparent 0%, rgba(0, 0, 0, 0.75) 100%), url("/assets/img/hero-home@2x.png"); } }
    #moving .hero .columns {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff; }
      #moving .hero .columns h1 {
        text-align: center; }
      #moving .hero .columns .small {
        font-size: 0.8em; }
  #moving .benefits {
    background: #F4F5F7; }
    #moving .benefits p.how {
      color: #000; }
    #moving .benefits p:last-child {
      margin-top: 50px; }
    #moving .benefits .benefit {
      background: #fff;
      display: flex;
      padding: 25px;
      margin: 20px;
      align-items: center;
      font-size: 1.5em;
      color: #000; }
      #moving .benefits .benefit span {
        flex-shrink: 1; }
      #moving .benefits .benefit .benefit-img {
        width: 100px;
        margin-right: 30px;
        text-align: center; }

#city {
  background: #F4F5F7; }
  #city .smaller-row {
    width: 65rem; }
  #city .section {
    padding: 30px; }
    #city .section h2 {
      font-size: 2em; }
  #city .hero {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/assets/img/hero-home.png");
    background-position: center, center;
    text-align: center; }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      #city .hero {
        background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/assets/img/hero-home@2x.png"); } }
    #city .hero .columns {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff; }
      #city .hero .columns h1 {
        text-align: center; }
      #city .hero .columns .small {
        font-size: 0.8em;
        color: #fff; }
    #city .hero .button {
      font-size: 1em; }
      @media screen and (max-width: 39.9375em) {
        #city .hero .button {
          font-size: 1.6em; } }
  #city #city-tab-bar {
    background: #67CE67; }
    #city #city-tab-bar ul {
      width: 100%;
      display: flex;
      justify-content: center;
      background: none;
      border: none;
      align-items: stretch;
      margin: 0px; }
      #city #city-tab-bar ul li {
        flex-grow: 1;
        background: none;
        border: none;
        text-align: center;
        font-size: 1.3em;
        font-weight: 400;
        display: flex;
        align-items: stretch;
        position: relative; }
        @media screen and (min-width: 40em) {
          #city #city-tab-bar ul li.is-active:after {
            bottom: 0px;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: #fff;
            border-width: 20px;
            transform: translateX(-50%); } }
        #city #city-tab-bar ul li:hover, #city #city-tab-bar ul li:focus, #city #city-tab-bar ul li.is-active, #city #city-tab-bar ul li:last-child, #city #city-tab-bar ul li a:hover, #city #city-tab-bar ul li a:focus, #city #city-tab-bar ul li a.is-active, #city #city-tab-bar ul li a:last-child {
          outline: none !important;
          background: none !important;
          font-weight: normal !important; }
        #city #city-tab-bar ul li a {
          color: #fff;
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 35px; }
      @media screen and (max-width: 39.9375em) {
        #city #city-tab-bar ul {
          flex-direction: column; }
          #city #city-tab-bar ul li a {
            padding: 20px; } }
  #city .tabs-content {
    border: none;
    background: none; }
    #city .tabs-content .tabs-panel {
      border: none;
      padding: 0px; }
    #city .tabs-content .footnote {
      color: #999999;
      font-size: 0.9em; }
    #city .tabs-content h2 {
      margin-bottom: 20px; }
    #city .tabs-content .checklist {
      text-align: left;
      font-size: 0.9em; }
      #city .tabs-content .checklist li {
        margin-bottom: 10px; }
  @media screen and (min-width: 40em) {
    #city .map-holder {
      padding: 0px 30px; } }
  #city h3 {
    margin-top: 20px; }
  #city .our-hours-days {
    font-weight: bold; }
  #city #how p {
    margin-bottom: 40px; }
  #city #how .how-image {
    margin-bottom: 60px; }
    @media screen and (min-width: 40em) {
      #city #how .how-image {
        max-width: 50%; } }
  #city #testimonials h2 {
    margin-bottom: 30px; }
  #city #testimonials .testimonial {
    text-align: left;
    border: 2px solid #7d7d7d;
    border-radius: 10px;
    position: relative;
    align-items: flex-start;
    padding: 10px 30px;
    padding-bottom: 30px;
    margin-bottom: 80px; }
    #city #testimonials .testimonial:after, #city #testimonials .testimonial:before {
      top: 100%;
      left: 10%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none; }
    #city #testimonials .testimonial:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #fff;
      border-width: 15px;
      margin-left: -15px; }
    #city #testimonials .testimonial:before {
      border-color: rgba(125, 125, 125, 0);
      border-top-color: #7d7d7d;
      border-width: 18px;
      margin-left: -18px; }
    #city #testimonials .testimonial p {
      font-size: 1em;
      padding: 0px;
      width: 100%; }
    #city #testimonials .testimonial .author {
      text-align: left; }
      #city #testimonials .testimonial .author b {
        margin-left: 10px; }
      #city #testimonials .testimonial .author .gold-star {
        font-size: 1em; }
  #city #resources .resource {
    align-items: flex-start;
    text-align: left;
    margin-bottom: 20px; }
    #city #resources .resource h3 {
      text-transform: uppercase;
      font-size: 1.2em;
      font-weight: bold; }

#contact {
  background: #F4F5F7; }
  #contact #content a {
    color: #6BC3FE; }
  #contact .hero {
    text-align: center;
    margin-bottom: 50px;
    background-image: url("/assets/img/hero-contact.png"); }
    @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      #contact .hero {
        background-image: url("/assets/img/hero-contact@2x.png"); } }
    #contact .hero h1 {
      text-align: center;
      color: #fff; }
    #contact .hero .contact-social {
      display: flex;
      justify-content: center;
      align-items: center; }
    #contact .hero a {
      color: #fff;
      margin-left: 10px;
      font-size: 2em;
      height: 60px;
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 4px solid #fff; }
    #contact .hero i {
      color: #fff; }
    #contact .hero .hero-social-facebook {
      background: #3B5998; }
    #contact .hero .hero-social-twitter {
      background: #55ACEE; }
    #contact .hero .hero-social-yelp {
      background: #AF0606; }
    #contact .hero .fa-circle {
      border: 2px solid #fff;
      border-radius: 50%; }
  #contact .header-row {
    margin-bottom: 20px; }
    #contact .header-row h1 {
      margin-bottom: 0px; }
  #contact #contact-box {
    padding: 30px;
    background: #fff;
    border: 2px solid #E8EBED;
    border-radius: 10px; }
    #contact #contact-box label {
      color: #4A4A4A;
      margin-top: 20px; }
      #contact #contact-box label:first-child {
        margin-top: 10px; }
    #contact #contact-box input, #contact #contact-box textarea {
      margin-top: 5px; }
    #contact #contact-box input[type="submit"] {
      margin-left: auto;
      padding: 1em 3em; }

#order-content {
  width: 1300px;
  overflow: visible; }
  @media screen and (max-width: 39.9375em) {
    #order-content > .columns {
      padding: 0px; } }
  @media screen and (max-width: 63.9375em) {
    #order-content > div:first-child {
      order: 2; } }

.ng-cloak {
  display: none !important; }

.pac-container * {
  font-family: 'Montserrat', sans-serif; }

.pac-container .pac-item-query {
  font-size: 1rem;
  margin-right: 10px;
  font-weight: normal;
  color: #4A4A4A; }

.pac-container .pac-item {
  font-size: 0.8rem; }

.form-angular-error {
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #ec5840; }

#order {
  background: #F4F5F7; }
  @media screen and (min-width: 80em) {
    #order {
      height: 100vh; } }
  #order #order-loading, #order #order-submitting {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: #fff;
    color: #4A4A4A;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999999; }
    #order #order-loading > div, #order #order-submitting > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;
      font-weight: bold;
      text-align: center; }
      #order #order-loading > div i, #order #order-submitting > div i {
        margin-bottom: 10px; }
  @media screen and (max-width: 63.9375em) {
    #order #got-a-question {
      width: 100%;
      border-top: 1px solid #F4F5F7;
      padding-top: 10px;
      text-align: center; }
      #order #got-a-question br {
        display: none; } }
  #order #order-submitting {
    background: rgba(255, 255, 255, 0.8); }
  #order #content {
    padding-bottom: 30px;
    overflow-y: auto;
    flex-shrink: 1;
    flex-grow: 1;
    margin-top: 0px;
    padding-top: 40px;
    position: relative; }
    @media screen and (max-width: 63.9375em) {
      #order #content {
        padding-top: 0px;
        padding-bottom: 0px; } }
  #order #order-header {
    background: #fff;
    display: flex;
    align-items: center;
    padding: 20px 40px;
    padding-top: 30px;
    border-bottom: 2px solid #E8EBED;
    margin-bottom: 0px;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 99;
    flex-shrink: 0; }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      #order #order-header .logo {
        text-align: center; } }
    @media screen and (max-width: 39.9375em) {
      #order #order-header {
        padding: 10px 0px; }
        #order #order-header .columns {
          text-align: center;
          padding-left: 20px;
          padding-right: 20px; }
        #order #order-header .logo {
          width: 140px; }
        #order #order-header > .row > .columns:first-child {
          margin-bottom: 30px; } }
  #order .back-box {
    line-height: 28px;
    margin-bottom: 10px; }
    #order .back-box a {
      color: #4A4A4A; }
  #order .order-timeline {
    justify-content: center; }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      #order .order-timeline {
        margin-top: 30px; } }
    #order .order-timeline, #order .order-timeline ul {
      display: flex;
      align-items: center; }
    #order .order-timeline ul {
      list-style: none;
      padding-left: 0px;
      margin-left: 0px; }
      #order .order-timeline ul li {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #E8EBED;
        position: relative;
        cursor: pointer;
        width: 80px;
        flex-shrink: 0; }
        @media screen and (min-width: 40em) {
          #order .order-timeline ul li {
            margin-right: 30px; }
            #order .order-timeline ul li:after {
              position: absolute;
              top: calc(19px);
              left: 60px;
              height: 2px;
              width: 70px;
              background: #E8E8ED;
              content: ' '; }
            #order .order-timeline ul li:last-child {
              margin-right: 0px; }
              #order .order-timeline ul li:last-child:after {
                display: none; } }
        @media screen and (min-width: 40em) {
          #order .order-timeline ul li .stage-num {
            border: 2px solid #E8EBED;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            text-align: center; }
          #order .order-timeline ul li .stage-label {
            width: 80px;
            margin-top: 5px;
            text-align: center;
            font-size: 0.78em; } }
        @media screen and (max-width: 39.9375em) {
          #order .order-timeline ul li {
            color: #6BC3FE;
            font-weight: bold;
            display: none; }
            #order .order-timeline ul li .stage-num {
              display: inline; }
              #order .order-timeline ul li .stage-num:before {
                content: 'Step '; }
              #order .order-timeline ul li .stage-num:after {
                content: ':'; } }
        #order .order-timeline ul li.current {
          display: flex; }
          @media screen and (min-width: 40em) {
            #order .order-timeline ul li.current .stage-num {
              border: 2px solid #67CE67;
              color: #4A4A4A; }
            #order .order-timeline ul li.current .stage-label {
              color: #4A4A4A; } }
          @media screen and (max-width: 39.9375em) {
            #order .order-timeline ul li.current {
              display: block; } }
        @media screen and (min-width: 40em) {
          #order .order-timeline ul li.complete .stage-num {
            border: 2px solid #67CE67;
            background: #67CE67;
            color: #fff; }
          #order .order-timeline ul li.complete .stage-label {
            color: #4A4A4A; }
          #order .order-timeline ul li.complete:after {
            background: #67CE67; } }
  #order form {
    width: 100%; }
  #order.where-page #content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/assets/img/where-image.jpg");
    background-position: bottom center, bottom center;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover; }
    #order.where-page #content .form-error {
      display: block; }
    #order.where-page #content button {
      min-width: 145px;
      text-align: center; }
    @media screen and (max-width: 39.9375em) {
      #order.where-page #content form > .row {
        margin-left: 0px;
        margin-right: 0px; }
        #order.where-page #content form > .row > .columns {
          padding-left: 0px;
          padding-right: 0px; }
      #order.where-page #content .order-box .button {
        width: calc(100% + 40px);
        margin: -20px;
        border-radius: 0px;
        margin-top: 20px; } }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      #order.where-page #content .order-box {
        max-width: 80vw;
        margin-left: auto;
        margin-right: auto; } }

.order-box {
  background: #fff;
  padding: 40px;
  border: 2px solid #E8EBED;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px; }
  @media screen and (max-width: 39.9375em) {
    .order-box {
      margin: 0px;
      padding: 20px;
      border-radius: 0px;
      border: none; } }
  .order-box h2 {
    width: calc(100% + 80px);
    margin-top: -40px;
    margin-left: -40px;
    margin-right: -40px;
    margin-bottom: 40px;
    padding: 25px 52px;
    font-size: 1.7em;
    display: flex;
    align-items: center;
    color: #4A4A4A;
    border-bottom: 2px solid #E8EBED;
    flex-wrap: wrap; }
    @media screen and (max-width: 39.9375em) {
      .order-box h2 {
        margin: -20px;
        margin-bottom: 20px;
        padding: 10px 20px;
        font-size: 1.3em;
        width: calc(100% + 40px); }
        .order-box h2 > span:not(.pickup-date) {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          box-sizing: border-box;
          border: 2px solid #67CE67;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          margin-bottom: 0px; } }
    @media screen and (max-width: 39.9375em) {
      .order-box h2 {
        margin-top: 9px; } }
    .order-box h2 > span:not(.pickup-date) {
      margin-right: 10px; }
      .order-box h2 > span:not(.pickup-date):after {
        content: ':'; }
      @media screen and (max-width: 39.9375em) {
        .order-box h2 > span:not(.pickup-date) {
          margin-right: 10px; }
          .order-box h2 > span:not(.pickup-date):after {
            content: ''; } }
    .order-box h2 img {
      margin-right: 15px; }
      @media screen and (max-width: 39.9375em) {
        .order-box h2 img {
          display: none; } }
    .order-box h2 small {
      width: 100%;
      margin-top: 20px;
      font-size: 0.58em;
      color: #4A4A4A;
      line-height: 1.3em;
      font-weight: 300; }
    .order-box h2 .pickup-date {
      font-size: 0.6em;
      margin-left: auto;
      margin-right: 0px;
      color: #4A4A4A; }
      .order-box h2 .pickup-date span {
        color: #6BC3FE;
        font-weight: bold; }
      @media screen and (max-width: 39.9375em) {
        .order-box h2 .pickup-date {
          display: block;
          width: 100%;
          margin-right: 0px;
          margin-left: 0px;
          margin-top: 10px;
          font-size: 0.8em; } }
  .order-box .next-prev-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    align-self: flex-end;
    margin-right: 25px; }
    .order-box .next-prev-holder .button span > span {
      margin-right: 10px; }
    .order-box .next-prev-holder .previous {
      text-align: center;
      color: #999999;
      text-decoration: underline;
      font-size: 0.8em;
      align-self: center;
      padding: 10px; }
    @media screen and (max-width: 39.9375em) {
      .order-box .next-prev-holder {
        margin: 0px;
        margin-top: 20px;
        padding: 0px;
        width: 100%;
        align-items: stretch; }
        .order-box .next-prev-holder .button {
          display: block;
          border-radius: 15px;
          margin: 0px; } }
  .order-box .button {
    padding: 1.3em 1.6em;
    margin-top: 20px; }
    @media screen and (max-width: 39.9375em) {
      .order-box .button {
        padding: 1em 1em; } }
    .order-box .button.next {
      width: auto;
      display: flex;
      align-items: center;
      font-size: 1.1em; }
      @media screen and (max-width: 39.9375em) {
        .order-box .button.next {
          font-size: 1.3em; } }
      .order-box .button.next.squared {
        border-radius: 10px; }
      .order-box .button.next:after {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "";
        font-weight: bold;
        margin-left: 40px;
        font-size: 1.5em; }
  .order-box label {
    color: #4A4A4A; }
    .order-box label input, .order-box label textarea, .order-box label select, .order-box label .wrap-dd-select {
      margin-top: 10px; }

#order #pricing-tabs {
  margin: 0 auto;
  margin-bottom: 10px;
  width: auto;
  align-self: center; }
  @media screen and (max-width: 39.9375em) {
    #order #pricing-tabs {
      width: 100%;
      margin: 0px; } }
  #order #pricing-tabs .tabs-title {
    min-width: 170px;
    text-align: center; }
    @media screen and (max-width: 39.9375em) {
      #order #pricing-tabs .tabs-title {
        min-width: 50%; } }

#order #rental-period-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 30px;
  width: 100%;
  margin-bottom: 30px; }
  @media screen and (max-width: 39.9375em) {
    #order #rental-period-slider {
      padding: 0px 10px;
      margin-bottom: 10px; } }
  #order #rental-period-slider label {
    color: #000;
    font-size: 1.2em;
    text-align: center;
    line-height: 1.2em; }
  #order #rental-period-slider > div {
    width: auto;
    flex-grow: 1;
    margin-left: 40px; }
    @media screen and (max-width: 39.9375em) {
      #order #rental-period-slider > div {
        width: 100%;
        margin-left: 0px; } }
  #order #rental-period-slider select {
    width: 100%;
    margin-top: 20px; }
  @media screen and (max-width: 39.9375em) {
    #order #rental-period-slider {
      flex-direction: column;
      margin-top: 20px; }
      #order #rental-period-slider label {
        width: 100%; }
        #order #rental-period-slider label br {
          display: none; }
      #order #rental-period-slider .rzslider {
        display: none; } }

#order #box-pricing-table .columns {
  margin-bottom: 2em;
  display: flex;
  flex-direction: row;
  align-items: stretch; }

#order #box-pricing-table .tabs-content, #order #box-pricing-table .tabs-panel {
  background: none;
  padding: 0px;
  margin: 0px;
  border: none; }

#order #box-pricing-table .tabs-panel > .row {
  margin-left: 0px;
  margin-right: 0px; }

#order .package {
  text-align: center;
  background: #2E2D3C;
  color: #fff;
  line-height: 1.8em;
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch; }
  #order .package h3 {
    text-align: center;
    color: #fff;
    font-size: 1.5em;
    margin-bottom: 0px;
    margin-top: 20px;
    border-bottom: 0px; }
  #order .package .package-count {
    color: #6BC3FE;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 1.2em; }
  #order .package .package-short-description, #order .package .package-description {
    font-size: 0.8em;
    padding: 5px 10px;
    line-height: 1.3em;
    margin-bottom: 20px; }
    @media screen and (max-width: 39.9375em) {
      #order .package .package-short-description, #order .package .package-description {
        font-size: 1em; } }
  #order .package .package-description {
    padding: 10px;
    font-weight: bold;
    margin-bottom: 20px; }
  #order .package .package-note {
    margin-bottom: 5px;
    font-size: 0.8em; }
  #order .package .package-order-now {
    background: #1C1B26;
    padding: 20px;
    width: 100%; }
  #order .package .package-price {
    font-size: 1.25em;
    color: #6BC3FE;
    margin-bottom: 20px;
    margin-top: auto; }
    @media screen and (max-width: 39.9375em) {
      #order .package .package-price {
        font-size: 1.5em; } }
    #order .package .package-price i {
      margin-left: 5px;
      color: #fff;
      font-size: 0.8em; }
    #order .package .package-price.no-include {
      margin-top: auto; }
    #order .package .package-price .dollar {
      font-size: 0.8em; }
  #order .package .whats-included {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    color: #fff;
    margin-top: auto;
    margin-bottom: 10px;
    font-size: 0.8em; }
    #order .package .whats-included i {
      margin-right: 3px; }
  #order .package .button {
    margin-top: 0px;
    display: block;
    color: #fff;
    margin-bottom: 0px;
    font-size: 1em;
    padding: 1.2em 1.8em; }
    #order .package .button.selected {
      color: #fff;
      background: #67CE67; }

#order .security-note {
  display: flex;
  align-items: center;
  font-size: 1em; }
  #order .security-note img {
    margin-right: 10px;
    width: auto;
    flex-shrink: 0; }
  #order .security-note span {
    flex-shrink: 1;
    color: #6BC3FE;
    font-weight: bold; }

#order #packing-supplies .next-prev-holder, #order #moving-supplies .next-prev-holder {
  margin-top: 0px; }

@media screen and (max-width: 39.9375em) {
  #order form #drop-off .row, #order form #pick-up .row, #order form #payment-details .row {
    margin-left: 0px;
    margin-right: 0px; }
    #order form #drop-off .row > .columns, #order form #pick-up .row > .columns, #order form #payment-details .row > .columns {
      padding-left: 0px;
      padding-right: 0px; } }

@media screen and (min-width: 40em) {
  #order #payment-details .next-prev-holder {
    margin-left: auto; } }

#order #payment-details .next-prev-holder .previous {
  display: none; }

#order #payment-details #payment-submission-row {
  margin-top: 30px; }
  @media screen and (max-width: 39.9375em) {
    #order #payment-details #payment-submission-row {
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 40px; } }

#order #sticky-anchor {
  max-height: 100% !important; }
  #order #sticky-anchor .order-box {
    width: 250px;
    max-width: auto; }
  @media screen and (min-width: 80em) {
    #order #sticky-anchor {
      position: fixed;
      width: 25%;
      top: 10.9em; }
      #order #sticky-anchor .order-box {
        width: calc(100% - 15px); } }
  @media screen and (min-width: 90em) {
    #order #sticky-anchor {
      width: 325px; }
      #order #sticky-anchor .order-box {
        width: 325px; } }
  @media screen and (max-width: 63.9375em) {
    #order #sticky-anchor {
      order: 1;
      width: 100%; }
      #order #sticky-anchor.sticky-container #order-cart {
        display: none; }
      #order #sticky-anchor .order-box {
        width: 100%; } }

#order .sticky {
  width: 100%; }

#order #order-cart {
  padding: 0px;
  max-height: calc(100vh - 31px); }
  @media screen and (max-width: 63.9375em) {
    #order #order-cart {
      max-height: auto;
      height: auto;
      overflow-y: hidden;
      min-width: 100%; } }
  @media screen and (max-width: 63.9375em) {
    #order #order-cart {
      margin: 0px;
      border-radius: 0px;
      border: none; } }
  @media screen and (min-width: 80em) {
    #order #order-cart {
      max-height: calc(100vh - 217px); } }
  #order #order-cart .cart-scroller {
    overflow-y: auto;
    flex-shrink: 1;
    padding: 20px; }
    @media screen and (max-width: 63.9375em) {
      #order #order-cart .cart-scroller {
        max-height: calc(100% - 45px); } }
  #order #order-cart h2 {
    width: 100%;
    padding: 20px;
    padding-bottom: 0px;
    text-align: left;
    border-bottom: 0px;
    margin: 0px;
    margin-bottom: 20px;
    color: #000;
    font-size: 1.3em; }
  #order #order-cart h4 {
    text-transform: uppercase;
    font-size: 0.68em;
    display: flex;
    align-items: center; }
    #order #order-cart h4 a {
      margin-left: auto;
      color: #67CE67; }
  #order #order-cart .invalid-package {
    border-bottom: none;
    color: #ffae00;
    margin-right: 5px; }
  #order #order-cart .basket-supplies {
    margin-top: 10px;
    margin-bottom: 10px; }
  #order #order-cart .basket-item {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    font-weight: 200;
    font-size: 1em; }
    #order #order-cart .basket-item .basket-item-price {
      margin-left: auto;
      color: #4A4A4A;
      font-size: 1em;
      font-weight: normal; }
  #order #order-cart .basket-item-quantity {
    color: #2E2D3C;
    font-weight: bold;
    margin-right: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    width: 55px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    #order #order-cart .basket-item-quantity a:first-child {
      margin-right: auto; }
    #order #order-cart .basket-item-quantity a:last-child {
      margin-left: auto; }
    #order #order-cart .basket-item-quantity a {
      color: #67CE67; }
    #order #order-cart .basket-item-quantity i {
      font-size: 0.7em; }
    #order #order-cart .basket-item-quantity .fa-trash {
      position: relative;
      top: -1px;
      color: #D75252; }
  #order #order-cart .basket-package {
    border-bottom: 2px solid #E8EBED;
    padding-bottom: 20px;
    margin-bottom: 10px; }
    #order #order-cart .basket-package .customise-package {
      color: #6BC3FE;
      text-transform: uppercase;
      font-size: 0.7em;
      margin-top: 10px;
      font-weight: bold;
      display: block; }
  #order #order-cart .basket-times {
    background: #EDFFED;
    margin-left: -20px;
    margin-right: -20px;
    padding: 15px 20px;
    font-weight: 200; }
    #order #order-cart .basket-times .time {
      display: block;
      font-size: 0.78em; }
  #order #order-cart .basket-total {
    background: #67CE67;
    padding: 20px;
    color: #fff;
    display: flex;
    align-items: center; }
    @media screen and (min-width: 80em) {
      #order #order-cart .basket-total {
        border-radius: 0px 0px 10px 10px; } }
    #order #order-cart .basket-total span {
      margin-left: auto;
      font-size: 1.23em; }

@media screen and (max-width: 63.9375em) {
  #order #cart-container {
    order: 1;
    position: relative; } }

#order #cart-container.open #sticky-anchor #order-cart {
  display: flex;
  flex-direction: column; }
  #order #cart-container.open #sticky-anchor #order-cart.is-stuck, #order #cart-container.open #sticky-anchor #order-cart.is-at-bottom {
    margin-top: 31px !important; }

@media screen and (max-width: 63.9375em) {
  #order #cart-toggle-container {
    width: 100%; }
  #order #cart-toggle {
    background: #67CE67;
    display: flex;
    padding: 5px 15px;
    color: #fff;
    min-width: 100%;
    right: 0px;
    left: 0px;
    z-index: 90; }
    #order #cart-toggle > span:not(.price) {
      margin-left: 5px; }
    #order #cart-toggle span.price {
      margin-left: auto; }
    #order #cart-toggle i {
      margin-top: 4px;
      margin-left: 10px; }
  #order #cart-toggle.is-at-bottom, #order #order-cart.is-at-bottom {
    position: fixed;
    top: 0px !important; }
  #order #cart-toggle.is-stuck-at-top, #order #order-cart.is-stuck-at-top {
    position: static; } }

#order .products-table .row {
  margin-left: 0px; }

@media screen and (max-width: 39.9375em) {
  #order .products-table {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
    margin-bottom: 20px; }
    #order .products-table .row {
      margin: 0px; } }

#order .products-table .columns {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: stretch; }
  @media screen and (max-width: 39.9375em) {
    #order .products-table .columns {
      padding: 0px;
      margin-bottom: 0px; } }

#order .products-table .product {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fff;
  border: 2px solid #E8EBED;
  border-radius: 6px;
  align-self: stretch;
  padding: 20px;
  margin-bottom: 0px;
  flex-shrink: 1;
  width: 100%; }
  @media screen and (max-width: 39.9375em) {
    #order .products-table .product {
      border: 0px;
      padding-bottom: 0px;
      height: 100vh;
      min-height: 100%; } }
  #order .products-table .product h3 {
    text-align: center;
    margin-bottom: 10px;
    color: #4A4A4A;
    font-size: 1.7em;
    font-weight: normal;
    height: 2.7em; }
  #order .products-table .product img {
    align-self: center;
    max-height: 180px; }
    @media screen and (max-width: 39.9375em) {
      #order .products-table .product img {
        flex-shrink: 1;
        max-height: 128px; } }
  #order .products-table .product .product-tagline {
    color: #6BC3FE;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px; }
    @media screen and (max-width: 39.9375em) {
      #order .products-table .product .product-tagline {
        font-size: 1.2em; } }
  #order .products-table .product .checklist {
    font-size: 0.7em; }
    #order .products-table .product .checklist li:before {
      color: #67CE67; }
    #order .products-table .product .checklist li {
      margin-bottom: 10px; }
  #order .products-table .product .product-description {
    margin-bottom: 0px;
    height: 8.5em;
    overflow-y: auto; }
    #order .products-table .product .product-description .checklist {
      margin-bottom: 10px; }
    @media screen and (max-width: 39.9375em) {
      #order .products-table .product .product-description {
        font-size: 1.3em;
        height: auto;
        text-align: left;
        overflow-y: hidden;
        flex-shrink: 0; } }
  #order .products-table .product .product-order {
    background: #2E2D3C;
    margin: -20px;
    margin-top: 10px;
    padding: 20px; }
    @media screen and (max-width: 39.9375em) {
      #order .products-table .product .product-order {
        margin-bottom: 0px; } }
  #order .products-table .product .button {
    padding: 1em 2em;
    text-align: center;
    width: 100%;
    display: block;
    margin: 0px;
    color: #fff;
    font-size: 1em; }
    #order .products-table .product .button i {
      margin-right: 10px; }
    @media screen and (max-width: 39.9375em) {
      #order .products-table .product .button {
        font-size: 1.6em; } }
  #order .products-table .product .product-price {
    text-align: center;
    color: #4A4A4A;
    margin-bottom: auto;
    font-size: 0.9em;
    margin-top: auto; }
    @media screen and (max-width: 39.9375em) {
      #order .products-table .product .product-price {
        font-size: 1.2em; } }

#order .has-note {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  #order .has-note input {
    width: 100%; }

#order .pickup-windows {
  border-bottom: 2px solid #E8EBED;
  padding-bottom: 20px;
  margin-bottom: 20px; }

#order #pick-up, #order #personal-details {
  padding-left: 0px;
  padding-right: 0px; }
  #order #pick-up h2, #order #personal-details h2 {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%; }
  #order #pick-up .row, #order #personal-details .row {
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 0px;
    margin-right: 0px; }
    @media screen and (max-width: 39.9375em) {
      #order #pick-up .row, #order #personal-details .row {
        padding-left: 20px;
        padding-right: 20px; } }
  @media screen and (max-width: 39.9375em) {
    #order #pick-up .next-prev-holder, #order #personal-details .next-prev-holder {
      margin-left: 0px;
      margin-right: 0px;
      width: 100%; } }
  #order #pick-up .button, #order #personal-details .button {
    margin-right: 40px;
    margin-left: 40px; }
    @media screen and (max-width: 39.9375em) {
      #order #pick-up .button, #order #personal-details .button {
        margin-left: 0px;
        margin-right: 0px; } }
  #order #pick-up .previous, #order #personal-details .previous {
    margin-right: 40px; }
    @media screen and (max-width: 39.9375em) {
      #order #pick-up .previous, #order #personal-details .previous {
        margin-right: 0px; } }

#order label.has-checkbox {
  display: flex;
  align-items: center; }
  #order label.has-checkbox input[type="checkbox"] {
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: 0px; }

#order .input-note {
  margin-left: auto;
  font-size: 0.6em;
  font-weight: bold; }
  @media screen and (max-width: 39.9375em) {
    #order .input-note {
      width: 100%;
      text-align: left;
      font-size: 0.8em; } }
  #order .input-note .input-warning {
    color: #ffae00; }
  #order .input-note .input-success {
    color: #3adb76; }
  #order .input-note .input-error {
    color: #ec5840; }

#order input.ng-touched:not(.ng-pristine):not(:focus).ng-invalid:not(:focus), #order input.ng-touched:not(.ng-pristine):not(:focus).ng-invalid-cc-number-type, #order select.ng-touched:not(.ng-pristine):not(:focus).ng-invalid:not(:focus), #order select.ng-touched:not(.ng-pristine):not(:focus).ng-invalid-cc-number-type, #order textarea.ng-touched:not(.ng-pristine):not(:focus).ng-invalid:not(:focus), #order textarea.ng-touched:not(.ng-pristine):not(:focus).ng-invalid-cc-number-type {
  background-color: rgba(236, 88, 64, 0.1);
  border-color: #ec5840; }

#order .form-error[ng-hide] {
  display: block; }

#order .card-wrapper {
  position: relative; }
  #order .card-wrapper .card-images {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px; }
    #order .card-wrapper .card-images img {
      transition: filter .5s ease-in-out, opacity .5s ease-in-out; }
      #order .card-wrapper .card-images img:not(.active) {
        -webkit-filter: grayscale(100%);
        /* Chrome, Safari, Opera */
        filter: grayscale(100%);
        opacity: .4; }
        @media screen and (min-width: 40em) and (max-width: 63.9375em) {
          #order .card-wrapper .card-images img:not(.active) {
            display: none; } }

.error-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
  color: #ec5840; }
  .error-list:first-child {
    margin-top: 10px; }
  @media screen and (max-width: 39.9375em) {
    .error-list {
      font-size: 0.8em; } }

#customisationModal {
  max-width: 100%;
  width: 500px; }
  #customisationModal .change-mind {
    font-size: 0.8em;
    text-align: center;
    color: #999999; }
    #customisationModal .change-mind .or {
      display: block;
      text-align: center; }
    #customisationModal .change-mind a {
      color: #999999;
      text-decoration: underline; }
  #customisationModal .package-item-row {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: #4A4A4A; }
    @media screen and (max-width: 39.9375em) {
      #customisationModal .package-item-row {
        justify-content: center;
        flex-wrap: wrap; } }
    #customisationModal .package-item-row .package-item-info {
      flex-grow: 1; }
      #customisationModal .package-item-row .package-item-info span {
        flex-shrink: 1; }
      @media screen and (max-width: 39.9375em) {
        #customisationModal .package-item-row .package-item-info {
          width: 100%;
          text-align: center;
          margin-bottom: 10px; } }
      #customisationModal .package-item-row .package-item-info .package-item-title {
        font-size: 1.6em;
        color: #010101; }
        @media screen and (max-width: 39.9375em) {
          #customisationModal .package-item-row .package-item-info .package-item-title {
            font-size: 1.3em;
            text-aling: center; } }
      #customisationModal .package-item-row .package-item-info .package-item-dimensions {
        color: #4A4A4A; }
    #customisationModal .package-item-row .package-item-quantity {
      display: flex;
      flex-direction: row;
      align-items: center; }
      #customisationModal .package-item-row .package-item-quantity i {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        border: 2px solid #67CE67;
        color: #67CE67;
        font-size: 1.4em;
        padding-top: 4px;
        text-align: center;
        padding-left: 1px; }
      #customisationModal .package-item-row .package-item-quantity a[disabled] {
        cursor: not-allowed; }
        #customisationModal .package-item-row .package-item-quantity a[disabled] i {
          color: rgba(103, 206, 103, 0.5);
          border-color: rgba(103, 206, 103, 0.5); }
      #customisationModal .package-item-row .package-item-quantity .quantity {
        font-size: 1.6em;
        color: #67CE67;
        font-weight: bold;
        margin: 10px;
        display: block;
        width: 2em;
        text-align: center; }
        #customisationModal .package-item-row .package-item-quantity .quantity input, #customisationModal .package-item-row .package-item-quantity .quantity input:focus, #customisationModal .package-item-row .package-item-quantity .quantity input:hover {
          border: none;
          font-weight: bold;
          box-shadow: none;
          text-align: center;
          background: none;
          color: #67CE67;
          padding: 0px;
          display: flex;
          align-items: center;
          margin: 0px; }
  #customisationModal .package-total-row {
    margin: 10px -20px;
    padding: 30px;
    background: #EDFFED;
    display: flex;
    flex-wrap: wrap; }
    #customisationModal .package-total-row h4 {
      width: 100%;
      text-transform: uppercase;
      color: #4A4A4A;
      font-size: 1em; }
    #customisationModal .package-total-row .package-name, #customisationModal .package-total-row .package-price {
      font-size: 1.3em; }
    #customisationModal .package-total-row .package-price {
      margin-left: auto; }
    #customisationModal .package-total-row .package-fee {
      font-size: 0.9em;
      width: 100%; }
    #customisationModal .package-total-row .package-line-item, #customisationModal .package-total-row .package-line-item-value {
      width: 50%; }
    #customisationModal .package-total-row .package-line-item-value {
      text-align: left;
      min-width: 55px;
      width: auto;
      margin-left: auto; }
  #customisationModal p {
    color: #000;
    text-align: center;
    margin-top: 15px;
    font-weight: 200;
    font-size: 0.75em; }
  #customisationModal .button {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 50%; }
    @media screen and (max-width: 39.9375em) {
      #customisationModal .button {
        width: 100%; } }
  #customisationModal .close-button {
    font-size: 1.5em; }

.include-modal {
  width: 800px;
  max-width: 90%;
  max-height: 80%;
  height: auto;
  min-height: auto;
  padding: 0px;
  position: absolute;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  height: auto;
  align-items: stretch;
  display: none; }
  @media screen and (max-width: 39.9375em) {
    .include-modal {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      top: 0px !important;
      transform: none;
      left: 0px;
      bottom: 0px;
      right: 0px; }
      .include-modal .close-button {
        padding-top: 5px; } }
  .include-modal .checklist li {
    margin-bottom: 15px; }
    .include-modal .checklist li:before {
      color: #67CE67; }
  .include-modal .modal-inner {
    max-height: 100%;
    width: 100%;
    display: flex;
    flex-shrink: 1;
    flex-direction: column;
    align-items: center; }
    .include-modal .modal-inner .modal-title {
      text-align: center;
      padding: 20px 40px;
      padding-top: 40px;
      align-self: stretch; }
    .include-modal .modal-inner .return-link {
      margin-top: 10px;
      color: #999999;
      text-decoration: underline; }
    .include-modal .modal-inner .included-image {
      flex-shrink: 2;
      max-height: 100%;
      max-width: 100%;
      height: auto;
      width: auto;
      border-radius: 0px; }

.reveal-overlay .include-modal {
  display: flex; }

.why {
  padding: 76px 50px;
  background: #6BC3FE;
  color: #fff; }
  .why h1, .why p {
    text-align: center; }
  .why h2 {
    margin-bottom: 30px;
    text-align: center;
    font-size: 2em; }
  @media screen and (max-width: 39.9375em) {
    .why {
      padding: 20px 5px; } }
  .why p {
    padding: 30px;
    margin-bottom: 49px; }
    @media screen and (max-width: 63.9375em) {
      .why p {
        padding: 5px;
        text-align: left;
        margin-bottom: 20px; } }
  .why .button {
    margin-top: 40px;
    font-size: 1.5em; }
    @media screen and (max-width: 39.9375em) {
      .why .button {
        width: 100%; } }
  @media screen and (max-width: 39.9375em) {
    .why .checklist {
      padding-left: 10px;
      padding-right: 10px;
      font-weight: bold; } }
  .why .checklist li:before {
    color: #fff; }

.advantages-testimonial {
  background: #fff; }

.advantages {
  padding: 50px;
  text-align: center; }
  @media screen and (max-width: 39.9375em) {
    .advantages {
      padding: 20px; } }
  .advantages h3 {
    font-size: 2em;
    font-weight: normal;
    margin-bottom: 30px;
    color: #4A4A4A; }
    @media screen and (max-width: 39.9375em) {
      .advantages h3 {
        padding: 20px;
        margin-bottom: 10px; } }
  .advantages p.meter-caption {
    font-size: 0.8em;
    color: #000;
    margin-top: 0px; }
  .advantages .progress {
    margin-bottom: 10px;
    margin-top: 20px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: #eee;
    position: relative;
    height: 2rem;
    border-radius: 100px;
    overflow: none; }
    .advantages .progress .progress-meter {
      background: #67CE67;
      border-radius: 100px;
      position: static; }
      .advantages .progress .progress-meter .progress-meter-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        text-align: center;
        font-size: 1.1em;
        font-weight: normal; }

.testimonial {
  color: #000;
  text-align: center;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 39.9375em) {
    .testimonial {
      padding: 30px 30px;
      padding-top: 30px;
      border-top: 2px solid #E8E8ED; } }
  @media screen and (min-width: 80em) {
    .testimonial {
      border-left: 1px solid #E8EBED; } }
  @media screen and (max-width: 63.9375em) {
    .testimonial {
      border-left: 1px solid #E8EBED; } }
  .testimonial p {
    margin: 20px auto;
    max-width: 100%;
    font-size: 1.3em;
    font-weight: 200; }
    @media screen and (max-width: 39.9375em) {
      .testimonial p {
        font-size: 1.1em; } }
  .testimonial img {
    margin-bottom: 20px;
    width: 40px;
    height: 40px; }
  .testimonial h4 {
    color: #000;
    margin-bottom: 0px; }
  .testimonial h5 {
    font-size: 0.9em;
    font-weight: normal;
    color: #000; }

.gold-star {
  font-size: 2em;
  color: #F2D311; }
  @media screen and (max-width: 39.9375em) {
    .gold-star {
      font-size: 2.6em; } }

#blog-index #content > .row {
  width: 1000px; }

#blog-index h1 {
  margin-top: 40px; }
  @media screen and (max-width: 39.9375em) {
    #blog-index h1 {
      font-size: 1.5em;
      padding: 0px 10px; } }

#blog-index #blog-posts {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    #blog-index #blog-posts {
      padding: 20px; } }

.blog-post {
  width: 100%;
  margin-top: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  @media screen and (max-width: 39.9375em) {
    .blog-post:first-child {
      margin-top: 10px; } }
  .blog-post.one-third {
    width: calc(33.3333% - 20px); }
  .blog-post.two-thirds {
    width: calc(66.6666% - 20px); }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .blog-post.one-third, .blog-post.two-thirds {
      width: calc(50% - 20px); } }
  .blog-post.left {
    margin-right: 20px; }
  .blog-post.right {
    margin-left: 20px; }
  @media screen and (max-width: 39.9375em) {
    .blog-post.one-third, .blog-post.two-thirds {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px; } }
  .blog-post.related-post {
    width: calc(50% - 40px);
    margin: 20px; }

.blog-post-inner {
  height: 340px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.5);
  padding: 30px;
  color: #fff;
  font-weight: normal;
  transition: background .2s ease-in-out; }
  .blog-post-inner:focus {
    color: #fff; }
  .blog-post-inner .post-title {
    margin-top: auto;
    font-weight: normal;
    font-size: 1.6em; }
    @media screen and (min-width: 80em) {
      .blog-post-inner .post-title {
        max-width: 50%; } }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      .blog-post-inner .post-title {
        max-width: 80%; } }
  .blog-post-inner .post-author {
    text-transform: uppercase;
    font-size: 0.6em;
    opacity: 0.9;
    max-width: 525px; }
    @media screen and (max-width: 63.9375em) {
      .blog-post-inner .post-author {
        font-size: 1em; } }
  .blog-post-inner:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.7); }

@media screen and (min-width: 40em) {
  .blog-post.one-third .blog-post-inner .post-title {
    max-width: 95%;
    font-size: 1.3em; }
  .blog-post.two-thirds .blog-post-inner .post-title {
    max-width: 80%;
    font-size: 1.3em; } }

.blog-categories {
  background: #6BC3FE;
  margin-top: 0px; }
  @media screen and (max-width: 39.9375em) {
    .blog-categories {
      display: none; } }
  .blog-categories .row {
    width: 90rem; }
  .blog-categories > div > div {
    padding: 76px 50px;
    color: #fff;
    display: flex;
    margin: 0px;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      .blog-categories > div > div {
        padding: 20px 5px; } }
  .blog-categories h2 {
    margin-bottom: 30px; }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      .blog-categories h2 {
        padding: 0px 20px; } }
  .blog-categories ul {
    display: flex;
    list-style: none;
    max-height: 330px;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row; }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      .blog-categories ul {
        max-height: 500px; } }
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      .blog-categories ul {
        flex-direction: row;
        max-height: none; } }
    .blog-categories ul li {
      margin-bottom: 0px;
      width: 25%;
      padding: 5px;
      display: block; }
      @media screen and (min-width: 40em) and (max-width: 63.9375em) {
        .blog-categories ul li {
          width: 50%; } }
    .blog-categories ul a {
      color: #fff; }

.blog-paginator {
  align-self: center;
  margin: 30px auto;
  width: 100%; }
  .blog-paginator ul {
    display: flex;
    align-items: center;
    justify-content: center; }
  .blog-paginator li:first-child {
    display: flex; }
  .blog-paginator .pagination-numbers {
    margin: 0px;
    flex-wrap: wrap;
    justify-content: center; }
    .blog-paginator .pagination-numbers li {
      margin-bottom: 5px; }
    @media screen and (max-width: 39.9375em) {
      .blog-paginator .pagination-numbers {
        display: none; } }
  .blog-paginator .nextprev {
    margin: 30px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #E8EBED;
    background: #fff;
    border-radius: 50%;
    flex-shrink: 0;
    transition: border-color .1s ease-in-out; }
    .blog-paginator .nextprev:hover:not(.disabled) {
      border-color: #67CE67;
      background: none; }
    .blog-paginator .nextprev i {
      color: #67CE67;
      font-size: 1.3em; }
    @media screen and (max-width: 39.9375em) {
      .blog-paginator .nextprev {
        margin: 10px;
        border-radius: 30px;
        width: auto;
        padding: 5px 20px; }
        .blog-paginator .nextprev.disabled {
          display: none; }
        .blog-paginator .nextprev span {
          margin: 0px; }
        .blog-paginator .nextprev i:first-child {
          margin-right: 10px; }
        .blog-paginator .nextprev i:last-child {
          margin-left: 10px; } }
  @media screen and (max-width: 39.9375em) {
    .blog-paginator li {
      display: block; } }
  .blog-paginator li a, .blog-paginator li.active span {
    padding: 0px;
    background: none; }
  .blog-paginator li a {
    padding: 0px; }
  .blog-paginator li a span, .blog-paginator li.active span {
    margin: 0px 10px;
    padding: 5px 0px;
    border-bottom: 2px solid transparent;
    transition: color .1s ease-in-out, border-bottom-color .1s ease-in-out; }
  .blog-paginator li li.active > span, .blog-paginator li li:hover > a > span {
    border-bottom: 2px solid #67CE67;
    color: #67CE67;
    background: none; }
  .blog-paginator li:hover > a {
    background: none; }

#blog-post .content-row {
  width: 800px; }
  #blog-post .content-row > div {
    padding-top: 40px; }
    @media screen and (max-width: 63.9375em) {
      #blog-post .content-row > div {
        padding: 30px; } }
  #blog-post .content-row h1 {
    color: #000; }

@media screen and (max-width: 63.9375em) {
  #blog-post h1 {
    font-size: 1.6em; } }

@media screen and (max-width: 39.9375em) {
  #blog-post h1 {
    font-size: 1.8em; } }

#blog-post .post-content p {
  margin: 1em 0 1.5em;
  padding: 0px;
  font-weight: 200; }
  @media screen and (max-width: 39.9375em) {
    #blog-post .post-content p {
      font-size: 1.14em;
      margin-top: 5px; } }

#blog-post .post-content h3 {
  margin-bottom: 10px;
  margin-top: 30px; }
  @media screen and (max-width: 63.9375em) {
    #blog-post .post-content h3 {
      margin-bottom: 10px; } }
  #blog-post .post-content h3 p {
    margin: 0px;
    padding: 0px; }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  #blog-post .post-content > h3 {
    padding: 0px; } }

#blog-post .post-content img.alignleft {
  float: left;
  margin: 0 0.5cm 0.5cm 0.5cm; }

#blog-post .post-content img.alignright {
  float: right;
  margin: 0.5cm 0 0.5cm 0.5cm; }

#blog-post .post-content img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }

@media screen and (max-width: 39.9375em) {
  #blog-post .post-content img.alignleft, #blog-post .post-content img.alignright {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%; } }

#blog-post .post-content a img.alignleft {
  margin: 0.5cm 0.5cm 0.5cm 0cm; }

#blog-post .post-content a img.align-right {
  margin: 0.5cm 0 0.5cm 0.5cm; }

#blog-post .post-info {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  margin-top: 10px;
  align-items: center; }
  @media screen and (max-width: 39.9375em) {
    #blog-post .post-info {
      flex-wrap: wrap; }
      #blog-post .post-info .post-author {
        width: 100%; }
      #blog-post .post-info .post-share {
        margin-top: 10px;
        margin-left: 0px;
        padding-left: 0px; } }

#blog-post .post-author {
  font-size: 0.8em; }

#blog-post .post-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 50px; }
  @media screen and (max-width: 39.9375em) {
    #blog-post .post-footer {
      flex-direction: column; }
      #blog-post .post-footer .post-share {
        margin-top: 10px;
        margin-left: 0px;
        padding-left: 0px; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    #blog-post .post-footer {
      padding: 20px 0px; } }

#blog-post .post-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  #blog-post .post-tags a {
    background: #F4F5F7;
    border-radius: 6px;
    margin-right: 10px;
    font-size: 0.8em;
    color: #000;
    padding: 10px 15px;
    margin-bottom: 10px; }

#blog-post .post-share {
  margin-left: auto;
  padding-left: 30px;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  flex-shrink: 0; }
  #blog-post .post-share .share {
    font-size: 1em; }

a.share {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5em;
  margin-left: 10px; }
  a.share, a.share:hover {
    color: #fff; }
  a.share.share-facebook {
    background: #3b5998; }
  a.share.share-twitter {
    background: #00aced; }

.related-posts {
  background: #F4F5F7;
  padding: 76px 50px; }
  .related-posts .row {
    width: 840px; }
  .related-posts h2 {
    padding: 0px 15px; }
  @media screen and (max-width: 39.9375em) {
    .related-posts {
      padding: 20px 0px; }
      .related-posts h2 {
        padding: 0px 20px; }
      .related-posts .related-post {
        width: 100%;
        margin: 10px 0px; } }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .related-posts {
      padding: 40px 15px; }
      .related-posts .post-title {
        font-size: 1.5em; } }
  .related-posts .post-title {
    max-width: 80%; }
  .related-posts > div > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row; }
    .related-posts > div > div h2 {
      width: 100%; }

#blog-heading {
  padding: 10px 30px;
  padding-top: 30px;
  margin: 0px; }
  #blog-heading h1 {
    margin: 0px;
    display: flex;
    align-items: center;
    color: #000; }
    #blog-heading h1 .heading-social {
      display: flex;
      margin-left: auto;
      align-items: center; }
      #blog-heading h1 .heading-social .share {
        border-radius: 0px;
        background: none;
        font-size: 1em; }
      #blog-heading h1 .heading-social .share-twitter {
        color: #00aced; }
      #blog-heading h1 .heading-social .share-facebook {
        color: #3b5998; }
  @media screen and (max-width: 39.9375em) {
    #blog-heading {
      padding-top: 20px; }
      #blog-heading h1 {
        padding: 0px;
        font-size: 1.7em; } }

#error-page #content {
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 40px;
  padding-bottom: 40px; }
  #error-page #content h1, #error-page #content p {
    text-align: center;
    margin-bottom: 20px; }

#locations .areas #area-list {
  height: 325px; }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    #locations .areas #area-list {
      height: 500px; } }
  @media screen and (max-width: 39.9375em) {
    #locations .areas #area-list {
      max-height: 800px;
      height: 800px; } }

#locations #locations-content {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/assets/img/locations-image.png");
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  padding: 50px 20px;
  min-height: 50vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  #locations #locations-content .form-error {
    display: block; }
  #locations #locations-content button {
    min-width: 161px;
    text-align: center; }

#locations .order-box {
  border-radius: 10px; }

@media screen and (max-width: 63.9375em) {
  #locations .order-box .row {
    margin: 0px; }
  #locations .order-box .button {
    width: 100%; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  #locations .order-box {
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto; } }

@media screen and (max-width: 39.9375em) {
  #locations .order-box {
    padding-top: 10px; }
    #locations .order-box h2 {
      margin-top: 0px; } }
