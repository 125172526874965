#contact {
	background: $clr-off-white;

	#content {
		a {
			color: $clr-highlight;
		}
	}

	.hero {
		text-align: center;
		//max-height: 350px;
		margin-bottom: 50px;

		background-image: url('/assets/img/hero-contact.png');

		@include breakpoint(retina) {
			background-image: url('/assets/img/hero-contact@2x.png');
		}

		h1 {
			text-align: center;
			color: $clr-white;
		}

		.contact-social {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		a {
			color: $clr-white;
			margin-left: 10px;
			font-size: 2em;
			height: 60px;
			width: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			border: 4px solid $clr-white;
		}

		i {
			color: $clr-white;
		}

		.hero-social-facebook {
			background: #3B5998;
		}

		.hero-social-twitter {
			background: #55ACEE;
		}

		.hero-social-yelp {
			background: #AF0606;
		}

		.fa-circle {
			border: 2px solid $clr-white;
			border-radius: 50%;
		}
	}

	.header-row {
		h1 {
			margin-bottom: 0px;
		}

		margin-bottom: 20px;
	}

	#contact-box {
		padding: 30px;
		background: $clr-white;
		border: 2px solid #E8EBED;
		border-radius: 10px;

		label {
			color: $clr-text;
			margin-top: 20px;

			&:first-child {
				margin-top: 10px;
			}
		}

		input,textarea {
			margin-top: 5px;
		}

		input[type="submit"] {
			margin-left: auto;
			padding: 1em 3em;
		}
	}
}