#home-hero {
	//height: 500px;
	background-image: linear-gradient(270deg, rgba(0,0,0,0.00) 0%, fade-out(#000000, .25) 100%), url('/assets/img/hero-home.png');

	@include breakpoint(retina) {
		background-image: linear-gradient(270deg, rgba(0,0,0,0.00) 0%, fade-out(#000000, .25) 100%), url('/assets/img/hero-home@2x.png');
	}

	@include breakpoint(small only) {
		background-position: 90%;
		background-size: cover;
	}

	.columns {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: $clr-white;

		h1 {
			text-align: center;
			font-size: 2em;
		}

		.small {
			font-size: 0.8em;
			color: $clr-white;
		}
	}

	.button {
		font-size: 1.6em;

		@include breakpoint(small only) {
			font-size: 1.6em;
		}
	}
}

#home {
	h2 {
		font-size: 2em;
	}
	
	.boxes {
		background: $clr-off-white;
	}

	.how {

		h2 {
			text-align: center;
		}

		padding-top: 76px;
		font-weight: 200;

		@include breakpoint(small only) {
			padding-top: 40px;
		}

		.columns {
			text-align: center;
			padding: 25px 40px;

			@include breakpoint(small only) {
				padding: 15px 30px;
			}

			img {
				margin-bottom: 30px;
				height: 202px;
				width: 202px;
				border: 6px solid #FFFFFF;
				box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.08);
				border-radius: 50%;

				@include breakpoint(small only) {
					margin-bottom: 15px;
				}
			}
		}

		.note {
			.columns {
				font-size: 0.65em;
				color: $clr-dulled;
				text-align: center;
				margin-bottom: 10px;
				margin-top: 10px;

				@include breakpoint(small only) {
					font-size: 0.8em;
				}
			}
		}
	}

	.checklist {
		padding-left: 50px;

		@include breakpoint(small only) {
			padding-left: 10px;
			font-weight: bold;
		}
	}
}

.areas {
	@include breakpoint(medium up) {
		background: $clr-primary;
	}

	@include breakpoint(medium only) {
		.row {
			padding: 20px;
		}
	}

	color: $clr-white;

	.row {
		width: 1440px;
	}

	h2 {
		color: $clr-white;
	}

	h3 {
		color: #000;
		margin-bottom: 15px;
	}

	.columns {
		padding: 80px 20px;

		@include breakpoint(medium-landscape up) {
			&:first-child {
				padding-left: 50px;
			}

			&:last-child {
				padding-right: 50px;
			}
		}

		@include breakpoint(medium only) {
			padding: 40px 20px;
		}

		@include breakpoint(small only) {
			padding: 10px;
		}

		&:first-child {
			@include breakpoint(small only) {
				padding: 20px;
				background: $clr-primary;
			}
		}
	}

	.tabs {
		margin-bottom: 20px;
		background: none;
		border: none;

		@include breakpoint(small only) {
			margin-bottom: 10px;
		}

		a {
			color: fade-out(#000, 0.7);
			padding: 10px 5px;
			margin: 0px 10px;
			font-size: 1em;
		}

		li:first-child {
			a {
				margin-left: 0px;
			}
		}

		li:hover {
			background: none;
		}

		.is-active a, a:hover {
			background: none;
			color: #fff;
			border-bottom: 2px solid $clr-white;
		}
	}

	.tabs-content {
		background: none;
		border: none;

		.tabs-panel.is-active {
			max-height: 200px;
			flex-shrink: 1;
			background: none;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: flex-start;
			padding-left: 0px;

			a {
				display: block;
				width: 25%;
				margin-bottom: 10px;
				color: $clr-white;

				&:hover {
					color: $clr-white;
				}
			}
		}
	}

	#area-list {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
		height: 525px;
		max-height: 525px;

		li {
			list-style: none;
			line-height: 1.65em;
			margin-right: 50px;
			color: fade-out($clr-white, 0.2);

			a {
				color: $clr-white;

				&:hover {
					color: $clr-secondary;
				}
			}
		}

		@include breakpoint(small only) {
			max-height: 825px;
			height: 825px;

			li {
				margin-right: 30px;
			}
		}

		@include breakpoint(medium-landscape only) {
			max-height: 670px;
			height: 670px;
		}
	}

	.area-note {
		padding-left: 20px;
		font-size: 0.8em;
	}

	.order-button {
		margin-top: 30px;
		font-size: 1.7em;
		padding: 1em 1.8em;

		@include breakpoint(small only) {
			width: 100%;
		}

		@include breakpoint(medium only) {
			width: 400px;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.quote-box {
		background: $clr-white;
		border-radius: 10px;
		padding: 20px;

		@include breakpoint(small only) {
			padding: 10px;
		}

		@include breakpoint(medium-landscape only) {
			margin-top: 30px;
		}

		input {

			&.ng-touched:not(.ng-pristine):not(:focus) {
				&.ng-invalid:not(:focus), &.ng-invalid-cc-number-type {
					background-color: rgba(236, 88, 64, 0.1);
					border-color: #ec5840;
				}
			}
		}

		.quote-submitted {
			color: $clr-primary;
			font-weight: bold;
			font-size: 0.8em;
			margin-bottom: 10px;
		}
	}
}