$clr-primary: #67CE67;
$clr-secondary: #2E2D3C;
//$clr-tertiary: #;

$clr-link: #6BC3FE;
$clr-white: #fff;
$clr-off-white: #F4F5F7;
$clr-big-red: #f94e36;

$clr-dulled: #999999;

$clr-text: #4A4A4A;

$clr-highlight: #6BC3FE;
$clr-warning: #E67E22;

$clr-social-fb: #3b5998;

$clr-social-twitter: #00aced;

$clr-hero-overlay: fade-out(#2e2d3c, .3);