.animate {
	opacity: 1;
}

.animate {
	&.ng-hide-add, &.animate-show.ng-hide-remove {
		transition: all linear 0.5s;
	}
}

.animate {
	&.ng-hide {
		opacity: 0;
	}
}