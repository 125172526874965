.advantages-testimonial {
	background: $clr-white;
}

.advantages {
	padding: 50px;
	text-align: center;

	@include breakpoint(small only) {
		padding: 20px;
	}

	h3 {
		font-size: 2em;
		font-weight: normal;
		margin-bottom: 30px;
		color: $clr-text;

		@include breakpoint(small only) {
			padding: 20px;
			margin-bottom: 10px;
		}
	}

	p {
		&.meter-caption {
			font-size: 0.8em;
			color: #000;
			margin-top: 0px;
		}
	}

	.progress {
		margin-bottom: 10px;
		margin-top: 20px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;

		background: #eee;
		position: relative;
		height: 2rem;
		border-radius: 100px;
		overflow: none;

		.progress-meter {
			background: $clr-primary;
			border-radius: 100px;
			position: static;

			.progress-meter-text {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
				text-align: center;
				font-size: 1.1em;
				font-weight: normal;
			}
		}
	}
}

.testimonial {
	color: #000;
	text-align: center;
	padding: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@include breakpoint(small only) {
		padding: 30px 30px;
		padding-top: 30px;
		border-top: 2px solid #E8E8ED;
	}

	@include breakpoint(large up) {
		border-left: 1px solid #E8EBED;
	}

	@include breakpoint(medium down) {
		border-left: 1px solid #E8EBED;
	}

	p {
		margin: 20px auto;
		max-width: 100%;
		font-size: 1.3em;
		font-weight: 200;

		@include breakpoint(small only) {
			font-size: 1.1em;
		}
	}

	img {
		margin-bottom: 20px;
		width: 40px;
		height: 40px;
	}

	h4 {
		color: #000;
		margin-bottom: 0px;
	}

	h5 {
		font-size: 0.9em;
		font-weight: normal;
		color: #000;
	}
}

.gold-star {
	font-size: 2em;
	color: #F2D311;

	@include breakpoint(small only) {
		font-size: 2.6em;
	}
}