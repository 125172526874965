#footer {
	background: $clr-secondary;
	line-height: 3em;
	margin-top: auto;
	padding: 60px;

	@include breakpoint(small only) {
		padding: 20px 0px;
	}

	ul {
		padding-left: 0px;
		margin-left: 0px;

		font-weight: 200;
		flex-grow: 1;

		@include breakpoint(small only) {
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			a {
				text-align: center;
				align-items: center;
				justify-content: center;
				padding: 10px;
			}
		}

		@include breakpoint(large up) {
			li:last-child a {
				padding-bottom: 0px;
			}
		}
	}

	@include breakpoint(medium only) {
		> .row > .columns {
			margin-bottom: 20px;
		}
	}

	b {
		font-weight: bold;
		color: $clr-white;
	}

	a {
		&,&:hover,&:focus {
			color: $clr-white;
		}

		@include breakpoint(medium up) {
			padding-left: 0px;
		}
	}

	p {
		color: $clr-white;
		font-weight: 200;
	}

	.footer-social {
		a {
			margin: 0 5px;
		}
	}

	@include breakpoint(small only) {
		text-align: center;
	}

	.footer-copy {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-start;

		@include breakpoint(medium down) {
			justify-content: center;
			align-items: center;
		}

		p {
			margin-bottom: 0px;
		}
	}

	> .row > .columns {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}