label {
	font-size: 1em;
	font-weight: 300;
}

input[type="text"], input[type="email"], input[type="date"], select, .wrap-dd-select, datepicker > input, textarea {
	background: $clr-off-white;
	box-shadow: inset 0px -3px 0px 0px rgba(0,0,0,0.10);
	border-radius: 6px;
	border: none;
	padding: 15px;
	height: auto;
	font-size: 1em;
	font-weight: normal;
	font-family: 'Montserrat', sans-serif;

	color: $clr-secondary;

	&::placeholder {
		color: #9B9B9B;
	}

	&:focus {
		border: none;
		outline: none;
	}

	@include breakpoint(small only) {
		font-size: 1.2em;
	}
}

input[type="date"] {
	min-height: 52px;
}

select {
	cursor: pointer;

	option[placeholder] {
		color: $clr-dulled;
	}
}

.select-container {
	position: relative;
	width: 100%;
	display: block;

	&:after {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 20px;
		@include fa-icon;
		content: $fa-var-caret-down;
	}

	input[type="date"]::-webkit-clear-button, input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-calendar-picker-indicator {
		-webkit-appearance: none;
		display: none;
	}
}

[data-abide-error] {
	border: none;
	border-radius: 6px;
	color: $clr-text;
}

input[type="checkbox"] {
	&:focus {
		outline: none;
	}
}

.wrap-dd-select {
	font-weight: normal;
	color: $clr-secondary;
	width: 100%;
	height: 51px;
	padding-top: 12px;
	margin-bottom: 16px;
	line-height: 1.5em;

	.dropdown {
		display: none;
	}


	&.active {
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
		box-shadow: none;

		.dropdown {
			display: block;
		}
	}

	.dropdown {
		width: 100%;
		margin-left: 0px;
		margin-top: -0px;
		border: 0px;
		box-shadow: none;
		background: $clr-white;
		border-radius: 0px 0px 10px 10px;
		color: $clr-secondary;

		border: 2px solid $clr-off-white;

		li {
			.dropdown-item {
				padding: 8px 15px;
				box-shadow: none;
				color: $clr-text;
			}

			&.divider-label {
				padding-left: 10px;
				color: $clr-dulled;
			}
		}

		li:first-child {
			.dropdown-item {
				border-top-right-radius: 0px;
				border-top-left-radius: 0px;
			}
		}

		li:hover {
			.dropdown-item {
				background: $clr-off-white;
			}
		}
	}
}

.ng-invalid {
	> label .wrap-dd-select {
		background: fade-out(#ec5840, .9);
	}
}

datepicker {
	font-size: 1em;

	._720kb-datepicker-calendar-day, ._720kb-datepicker-calendar-day._720kb-datepicker-active {
		background: transparent;

		transition: none;

		&:hover {
			//background: lighten($clr-primary, 30%);
			//color: #000;
			background: $clr-primary;
			color: $clr-white;
		}
	}

	._720kb-datepicker-calendar-header {
		&:nth-child(odd), &:nth-child(even) {
			background: $clr-primary;
		}

		&, a {
			font-weight: bold;
		}

		._720kb-datepicker-calendar-years-pagination-pages {
			display: none;
		}

		._720kb-datepicker-disabled {
			display: none;
		}
	}

	select {
		background: $clr-off-white;
		box-shadow: inset 0px -3px 0px 0px rgba(0,0,0,0.10);
		border-radius: 6px;
		border: none;
		padding: 15px;
		height: auto;
		font-size: 1em;
		font-weight: normal;
		font-family: 'Montserrat', sans-serif;
		color: $clr-secondary;

		option {
			background: $clr-off-white;
			color: $clr-secondary;
		}
	}
}

.rzslider {
	margin-bottom: 50px;

	.rz-ticks {
		top: 0px;
		right: 0px;
		bottom: 0px;
		height: 100%;
		height: 25px;
		border-radius: 25px;
		display: flex;
		border: 3px solid $clr-off-white;

		.rz-tick {
			flex-grow: 1;
			position: static;
			margin: 0px;
			height: 100%;
			width: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 0px;
			background: $clr-white;
			flex-shrink: 1;
			transform: none!important;
			border-right: 3px solid $clr-off-white;
			position: relative;

			&:first-child {
				border-top-left-radius: 25px;
				border-bottom-left-radius: 25px;
			}

			&:last-child {
				border-top-right-radius: 25px;
				border-bottom-right-radius: 25px;
				border-right: none;
			}

			.rz-tick-value {
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				position: absolute;
				flex-shrink: 1;
				font-size: 0.8em;
			}

			&.rz-selected {
				background: $clr-white;

			}

			&.rz-last-selected {
				.rz-tick-value {
					position: absolute;
					background: $clr-primary;
					color: $clr-white;
					font-size: 1em;
					border-radius: 25px;
					padding: 2px 10px;
					width: 120%;
					z-index: 10;
				}
			}
		}
	}

	.rz-bar {
		background: $clr-off-white;
		height: 25px;
		border-radius: 25px;
		border: 3px solid $clr-off-white;

		&.rz-selection {
			display: none;
		}
	}

	.rz-pointer {
		top: -5px;
		background: none;
		width: 100px;
		height: 34px;

		&:after {
			display: none;
		}

		&:focus {
			outline: none;
		}
	}

	.rz-bubble {
		bottom: -25px;
		color: $clr-white;
		background: $clr-primary;
		border-radius: 25px;
		z-index: 2;
		padding: 3px 20px;
	}
}