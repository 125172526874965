$post-width: 800px;

#blog-index {
	#content > .row {
		width: 1000px;
	}

	h1 {
		margin-top: 40px;

		@include breakpoint(small only) {
			font-size: 1.5em;
			padding: 0px 10px;
		}
	}

	#blog-posts {
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;

		@include breakpoint(medium only) {
			padding: 20px;
		}
	}
}

.blog-post {
	width: 100%;
	margin-top: 40px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	@include breakpoint(small only) {
		&:first-child {
			margin-top: 10px;
		}
	}

	&.one-third {
		width: calc(33.3333% - 20px);
	}

	&.two-thirds {
		width: calc(66.6666% - 20px);
	}

	@include breakpoint(medium only) {
		&.one-third, &.two-thirds {
			width: calc(50% - 20px);
		}
	}

	&.left {
		margin-right: 20px;
	}

	&.right {
		margin-left: 20px;
	}

	@include breakpoint(small only) {
		&.one-third, &.two-thirds {
			width: 100%;
			margin-left: 0px;
			margin-right: 0px;
		}
	}

	&.related-post {
		width: calc(50% - 40px);
		margin: 20px;
	}
}

.blog-post-inner {
	height: 340px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background: rgba(0,0,0,0.50);
	padding: 30px;
	color: $clr-white;
	font-weight: normal;
	transition: background .2s ease-in-out;

	&:focus {
		color: $clr-white;
	}

	.post-title {
		margin-top: auto;
		font-weight: normal;
		font-size: 1.6em;

		@include breakpoint(large up) {
			max-width: 50%;
		}

		@include breakpoint(medium only) {
			max-width: 80%;
		}
	}

	.post-author {
		text-transform: uppercase;
		font-size: 0.6em;
		opacity: 0.9;

		max-width: 525px;

		@include breakpoint(medium down) {
			font-size: 1em;
		}
	}

	&:hover {
		color: $clr-white;
		background: rgba(0, 0, 0, 0.7);
	}
}

@include breakpoint(medium up) {
	.blog-post {
		&.one-third {
			.blog-post-inner {
				.post-title {
					max-width: 95%;
					font-size: 1.3em;
				}
			}
		}

		&.two-thirds {
			.blog-post-inner {
				.post-title {
					max-width: 80%;
					font-size: 1.3em;
				}
			}
		}
	}
}

.blog-categories {
	background: $clr-highlight;
	margin-top: 0px;

	@include breakpoint(small only) {
		display: none;
	}

	.row {
		width: 90rem;
	}

	> div > div {
		padding: 76px 50px;
		color: $clr-white;
		display: flex;
		margin: 0px;
		flex-direction: column;
		width: 100%;
		max-width: 100%;
		flex: 0 0 100%;

		@include breakpoint(medium only) {
			padding: 20px 5px;
		}
	}

	h2 {
		margin-bottom: 30px;

		@include breakpoint(medium only) {
			padding: 0px 20px;
		}
	}

	ul {
		display: flex;
		list-style: none;
		max-height: 330px;
		width: 100%;

		@include breakpoint(medium only) {
			max-height: 500px;
		}

		flex-wrap: wrap;
		flex-direction: row;

		@include breakpoint(medium only) {
			flex-direction: row;
			max-height: none;
		}


		li {
			margin-bottom: 0px;
			width: 25%;
			padding: 5px;
			display: block;

			@include breakpoint(medium only) {
				width: 50%;
			}
		}

		a {
			color: $clr-white;
		}
	}
}

.blog-paginator {
	align-self: center;
	margin: 30px auto;
	width: 100%;

	ul {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	li:first-child {
		display: flex;
	}

	.pagination-numbers {
		margin: 0px;
		flex-wrap: wrap;
		justify-content: center;

		li {
			margin-bottom: 5px;
		}

		@include breakpoint(small only) {
			display: none;
		}
	}

	.nextprev {
		margin: 30px;
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid #E8EBED;
		background: #fff;
		border-radius: 50%;
		flex-shrink: 0;

		transition: border-color .1s ease-in-out;

		&:hover:not(.disabled) {
			border-color: $clr-primary;
			background: none;
		}

		i {
			color: $clr-primary;
			font-size: 1.3em;
		}

		@include breakpoint(small only) {
			margin: 10px;
			border-radius: 30px;
			width: auto;
			padding: 5px 20px;

			&.disabled {
				display: none;
			}

			span {
				margin: 0px;
			}

			i {
				&:first-child {
					margin-right: 10px;
				}

				&:last-child {
					margin-left: 10px;
				}
			}
		}
	}

	li {
		@include breakpoint(small only) {
			display: block;
		}

		a, &.active span {
			padding: 0px;
			background: none;
		}

		a {
			padding: 0px;
		}

		a span, &.active span {
			margin: 0px 10px;
			padding: 5px 0px;
			border-bottom: 2px solid transparent;
			transition: color .1s ease-in-out, border-bottom-color .1s ease-in-out;
		}

		li { 
			&.active > span, &:hover > a > span {
				border-bottom: 2px solid $clr-primary;
				color: $clr-primary;
				background: none;
			}
		}

		&:hover > a {
			background: none;
		}
	}
}

#blog-post {
	.content-row {
		> div {
			@include breakpoint(medium down) {
				padding: 30px;
			}

			padding-top: 40px;
		}

		h1 {
			color: #000;
		}

		width: $post-width;
	}

	h1 {
		@include breakpoint(medium down) {
			font-size: 1.6em;
		}

		@include breakpoint(small only) {
			//padding: 0px 10px;
			font-size: 1.8em;
		}
	}

	.post-content {
		p {
			margin: 1em 0 1.5em;
			padding: 0px;
			font-weight: 200;

			@include breakpoint(small only) {
				font-size: 1.14em;
				//padding: 10px 30px;
				margin-top: 5px;
			}	
		}

		h3 {
			margin-bottom: 10px;
			margin-top: 30px;

			@include breakpoint(medium down) {
				//padding: 0px 20px;
				margin-bottom: 10px;
			}

			p {
				margin: 0px;
				padding: 0px;
			}
		}

		> h3 {
			@include breakpoint(medium only) {
				padding: 0px;
			}

			@include breakpoint(small only) {
				//padding: 0px 30px;
			}
		}

		img {

			&.alignleft {
				float: left;
				margin: 0 0.5cm 0.5cm 0.5cm;
			}

			&.alignright {
				float: right;
				margin: 0.5cm 0 0.5cm 0.5cm;
			}

			&.aligncenter {
				display: block;
				margin-left: auto;
				margin-right: auto;
				width: 100%;
			}

			@include breakpoint(small only) {
				&.alignleft, &.alignright {
					float: none;
					display: block;
					margin-left: auto;
					margin-right: auto;
					width: 100%;
				}
			}
		}

		a {
			img {
				&.alignleft {
					margin: 0.5cm 0.5cm 0.5cm 0cm;
				}

				&.align-right {
					margin: 0.5cm 0 0.5cm 0.5cm;
				}
			}
		}
	}

	.post-info {
		width: 100%;
		display: flex;
		margin-bottom: 30px;
		margin-top: 10px;
		align-items: center;

		@include breakpoint(small only) {
			//padding: 0px 10px;
			flex-wrap: wrap;

			.post-author {
				width: 100%;
			}

			.post-share {
				margin-top: 10px;
				margin-left: 0px;
				padding-left: 0px;
			}
		}
	}

	.post-author {
		font-size: 0.8em;
	}

	.post-footer {
		display: flex;
		flex-direction: row;
		width: 100%;
		margin-bottom: 40px;
		margin-top: 50px;

		@include breakpoint(small only) {
			flex-direction: column;
			//padding: 20px;

			.post-share {
				margin-top: 10px;
				margin-left: 0px;
				padding-left: 0px;
			}
		}

		@include breakpoint(medium only) {
			padding: 20px 0px;
		}
	}

	.post-tags {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		a {
			background: $clr-off-white;
			border-radius: 6px;
			margin-right: 10px;
			font-size: 0.8em;
			color: #000;
			padding: 10px 15px;
			margin-bottom: 10px;
		}
	}

	.post-share {
		margin-left: auto;
		padding-left: 30px;
		font-size: 0.8em;
		display: flex;
		align-items: center;
		flex-shrink: 0;

		.share {
			font-size: 1em;
		}
	}
}

a.share {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.5em;
	margin-left: 10px;

	&, &:hover {
		color: $clr-white;
	}

	&.share-facebook {
		background: $clr-social-fb;
	}

	&.share-twitter {
		background: $clr-social-twitter;
	}
}

.related-posts {
	background: $clr-off-white;
	padding: 76px 50px;

	.row {
		width: $post-width + 40px;
	}

	h2 {
		padding: 0px 15px;
	}

	@include breakpoint(small only) {
		padding: 20px 0px;

		h2 {
			padding: 0px 20px;
		}

		.related-post {
			width: 100%;
			margin: 10px 0px;
		}
	}

	@include breakpoint(medium only) {
		padding: 40px 15px;

		.post-title {
			font-size: 1.5em;
		}
	}

	.post-title {
		max-width: 80%;
	}

	> div > div {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;

		h2 {
			width: 100%;
		}
	}
}

#blog-heading {
	padding: 10px 30px;
	padding-top: 30px;
	margin: 0px;

	h1 {
		margin: 0px;
		display: flex;
		align-items: center;
		color: #000;

		.heading-social {
			display: flex;
			margin-left: auto;
			align-items: center;

			.share {
				border-radius: 0px;
				background: none;
				font-size: 1em;
			}

			.share-twitter {
				color: $clr-social-twitter;
			}

			.share-facebook {
				color: $clr-social-fb;
			}
		}
	}

	@include breakpoint(small only) {
		padding-top: 20px;

		h1 {
			padding: 0px;
			font-size: 1.7em;
		}
	}
}