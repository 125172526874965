.call-to-action {
	background: $clr-highlight;

	padding: 30px 20px;

	.columns {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.button {
		margin: 0px;
		display: block;
		font-size: 1.5em;
		padding: 0.8em 2em;

		@include breakpoint(small only) {
			margin-top: 20px;
		}
	}

	.cta-text {
		font-size: 1.8em;
		font-weight: bold;
		color: $clr-white;

		@include breakpoint(small only) {
			text-align: center;
		}
	}
}