$package-top-height: 175px;

#pricing {
	background: $clr-off-white;

	.hero {
		//height: 400px;
		width: 100%;
		margin-bottom: 50px;
	}

	#content {
		padding-bottom: 30px;

		display: flex;
		flex-direction: column;
		align-items: center;

		@include breakpoint(small only) {
			#page-content {
				padding: 10px;
			}
		}
	}

	#box-pricing-table, &.moving-pricing, &.packing-pricing {
		.checklist {
			li:before {
				color: $clr-primary;
			}
		}
	}

	.hero {
		.button {
			align-self: center;
			margin: 0 auto;
		}

		background-image: linear-gradient(fade-out($clr-secondary, .3), fade-out($clr-secondary, .3)), url('/assets/img/hero-pricing.jpg');
		background-position: top center, top center;
		background-repeat: no-repeat, no-repeat;
		background-size: cover, cover;

		@include breakpoint(retina) {
			background-image: linear-gradient(fade-out($clr-secondary, .3), fade-out($clr-secondary, .3)), url('/assets/img/hero-pricing@2x.jpg');
		}

		@include breakpoint(small only) {
			//display: none;
		}
	}

	&.moving-pricing #content {
		.columns {
			justify-content: center;
			flex-direction: column;
			display: flex;
			align-items: flex-start;

			&.product-image {
				align-items: center;
			}
		}


		.hero h1 {
			text-align: center;
			width: 100%;
			color: $clr-white;
		}

		#supplies-list {
			padding: 0px 30px;
		}

		.product {
			@include breakpoint(small only) {
				> .row {
					margin-left: 0px;
					margin-right: 0px;
				}
			}

			.button {
				@include breakpoint(medium-landscape down) {
					padding: 1em 2em;
				}

				@include breakpoint(small only) {
					display: block;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}


	&.box-pricing #content {
		background: $clr-white;
		width: 100%;
		max-width: 100%;
		padding-bottom: 0px;

		.boxes {
			background: $clr-off-white;
			padding-top: 40px;
			margin-top: 60px;
		}

		.hero {

			@include breakpoint(medium up) {
				margin-bottom: -$package-top-height;
				min-height: 350px;
				height: 350px;
				align-items: flex-start;

				h1 {
					margin: 0 auto;
					margin-top: 60px;
					color: $clr-white;
					font-size: 2.6em;
					font-weight: 400;
					text-align: center;
				}
			}

			@include breakpoint(small only) {
				h1 {
					width: 100%;
				}
			}
		}

		.why {
			width: 100%;
			margin-top: 50px;
		}

		.box-list {
			display: flex;
			justify-content: space-around;
			width: $global-width;
			max-width: 100%;
			margin: 0 auto;
			overflow: hidden;

			@include breakpoint(medium-landscape down) {
				flex-wrap: wrap;
			}

			@media #{breakpoint(medium up)} and #{breakpoint(medium-landscape down)}  {
				padding: 20px;
				padding-top: 0px;
			}
		}

		.package-holder {
			flex-shrink: 1;
			flex-grow: 1;
			width: auto;
			display: flex;
			align-items: stretch;
			justify-content: flex-start;
			margin: 15px;
			margin-top: 0px;

			width: calc(20% - 30px);

			@include breakpoint(small only) {
				width: 100%;
			}

			@media #{breakpoint(medium up)} and #{breakpoint(medium-landscape down)}  {
				margin: 10px;
				margin-top: 0px;
				margin-bottom: 20px;

				width: calc(50% - 20px);
				flex-grow: 0;
			}

			&:nth-child(6) {
				display: none;

				@media #{breakpoint(medium up)} and #{breakpoint(medium-landscape down)}  {
					display: flex;
				}
			}
		}

		.package {
			flex-shrink: 1;
			width: 100%;
			text-align: center;
			background: $clr-white;
			color: $clr-secondary;
			line-height: 1.8em;
			border-radius: 6px;
			overflow: hidden;
			transition: .3s ease-in-out box-shadow;

			display: flex;
			align-items: stretch;
			flex-direction: column;
			box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);

			.package-top {
				display: flex;
				flex-direction: column;
				align-items: center;

				background: $clr-primary;
				padding: 20px;
				color: $clr-white;
				height: $package-top-height;

				@include breakpoint(small only) {
					height: auto;
				}
			}

			h2 {
				font-size: 2.3em;
				word-wrap: nowrap;

				@include breakpoint(small only) {
					font-size: 2.6em;
				}
			}

			h3 {
				text-transform: uppercase;
				font-size: 1.2em;
				word-wrap: nowrap;

				@include breakpoint(small only) {
					font-size: 1.6em;
				}
			}

			.package-section {
				border-bottom: 1px solid fade-out($clr-secondary, 0.7);
				text-align: center;
				padding: 10px;
				font-size: 0.9em;

				@include breakpoint(small only) {
					font-size: 1.3em;
				}
			}

			.package-count {
				color: $clr-highlight;
				font-weight: bold;
			}

			.package-short-description, .package-description {
				font-size: 0.8em;
			}

			.package-description {
				padding: 20px 15px;
				line-height: 1.8em;

				text-align: left;

				ul {
					margin: 0px;
					padding: 0px;

					&.checklist {
						li:before {
							color: $clr-primary;
						}
					}
				}

				@include breakpoint(small only) {
					font-size: 1.2em;
				}
			}

			.package-order-now {
				padding: 20px 15px;
				margin-top: auto;
				border-top: 1px solid fade-out($clr-secondary, 0.7);
				
				@include breakpoint(small only) {
					padding: 15px;	
				}

				@include breakpoint(medium only) {

				}
			}

			.button {
				display: block;
				background: $clr-big-red;
				color: $clr-white;
				margin-bottom: 0px;
				font-size: 1.1em;
				text-transform: uppercase;
				padding: 1em 1em;

				@include breakpoint(small only) {
					font-size: 1.4em;
					padding: 0.8em 1.5em;
				}

				@media #{breakpoint(medium up)} and #{breakpoint(medium-landscape down)}  {
					display: inline-block;
					padding: 1em 2em;
				}
			}
		}
	}
}

.product {
	background: #FFFFFF;
	border: 2px solid #7D7D7D;
	border-radius: 10px;
	margin-bottom: 20px;
	padding: 30px;

	.product-image {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	h3 {
		font-size: 1.35em;
		font-weight: bold;
		color: #000;
		margin-bottom: 0px;
	}

	.product-price {
		color: $clr-primary;
		font-size: 1.35em;
		margin-bottom: 20px;
		display: block;
	}

	.product-description {
		font-weight: 200;
		font-size: 1em;
	}

	.button {
		font-size: 1.5em;
		padding: 1em 3em;
		margin-top: 10px;
	}
}